<template>
  <div
    class="select-search"
    v-on-clickaway:mousedown="() => (hasFocus = false)"
  >
    <input
      type="text"
      v-model="query"
      class="w-full"
      :class="query ? 'rounded-none rounded-t' : ''"
      :tabindex="tabindex ? tabindex : 0"
      ref="searchInput"
      :placeholder="placeholder"
      @input="handleFieldInput"
      @focus="hasFocus = true"
    />
    <div
      class="results"
      v-if="(query || (!query && showOnFocus && hasFocus)) && !value"
    >
      <div
        v-for="(item, index) in filteredDataset"
        :key="index"
        @click="selectItem(item)"
        @mouseenter="selectedIndex = index"
        class="result"
        :class="selectedIndex === index ? 'bg-offwhite font-medium' : ''"
        ref="selectSearchItem"
      >
        {{ label(item) }}
      </div>
      <ClipLoader v-if="retrieving" class="m-2" />
      <div v-if="!retrieving && filteredDataset.length === 0" class="p-2">
        {{
          query.length > 3 ? "Tulemusi pole" : "Palun trüki vähemalt 3 tähte"
        }}
      </div>
    </div>
  </div>
</template>
<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
    filterFunc: {
      type: Function,
      default: null,
    },
    valueToSelect: {
      type: String || Object || Number,
      default: null,
    },
    label: {
      type: Function,
      default: (item) => item,
    },
    tabindex: {
      type: Number,
      default: null,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    retrieving: Boolean,
    value: {
      type: null,
      default: null,
    },
    placeholder: {
      type: String,
      default: "Alusta kirjutamist",
    },
    showOnFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: "",
      selectedIndex: null,
      hasFocus: false,
    };
  },
  mounted() {
    if (this.focus) this.$refs.searchInput.focus();
    window.addEventListener("keydown", this.keyboardSelectHandler);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.keyboardSelectHandler);
  },
  methods: {
    selectItem(item) {
      this.selectedIndex = null;
      this.$emit("input", this.valueToSelect ? item[this.valueToSelect] : item);
      this.query = "";
      this.hasFocus = false;
    },
    handleFieldInput(e) {
      this.$emit("fieldInput", e);
    },
    scrollItemIntoView(index) {
      if (
        this.$refs.selectSearchItem === undefined ||
        this.$refs.selectSearchItem[index] === undefined
      )
        return;
      this.$refs.selectSearchItem[index].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    },
    keyboardSelectHandler(e) {
      if (e.keyCode === 38) {
        if (this.selectedIndex === null)
          this.selectedIndex = this.filteredDataset.length;
        if (this.selectedIndex > 0) this.selectedIndex -= 1;
        this.scrollItemIntoView(this.selectedIndex);
        return;
      }
      if (e.keyCode === 40) {
        if (this.selectedIndex === null) this.selectedIndex = -1;
        if (this.selectedIndex < this.filteredDataset.length - 1)
          this.selectedIndex += 1;
        this.scrollItemIntoView(this.selectedIndex);
        return;
      }
      if (e.keyCode === 13 && this.selectedIndex !== null) {
        this.selectItem(this.filteredDataset[this.selectedIndex]);
      }
    },
  },
  computed: {
    filteredDataset() {
      if (this.retrieving) return [];
      if (!this.dataset) return [];
      if (!this.filterFunc || (this.showOnFocus && !this.query))
        return this.dataset;
      return this.dataset.filter((x) => this.filterFunc(x, this.query));
    },
  },
  watch: {
    query() {
      this.selectedIndex = 0;
      this.$emit("queryUpdate", this.query);
    },
  },
  components: { ClipLoader },
};
</script>
<style scoped lang="scss">
.select-search {
  @apply bg-offwhite rounded relative;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    @apply bg-offwhite rounded-none rounded-br;
  }
  ::-webkit-scrollbar-thumb {
    @apply rounded;
  }
  .results {
    @apply absolute w-full bg-white max-h-64 overflow-y-scroll overflow-x-hidden rounded-b shadow-xl;
    top: 100%;
    .result {
      @apply p-2 cursor-pointer duration-100;
    }
  }
}
</style>
