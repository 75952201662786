import RevenueGeneratorView from "@/views/company/RevenueGeneratorView";
import SentOutgoingInvoiceView from "@/views/ava/SentOutgoingInvoiceView.vue";
import config from "@/../gewodo.config.json";

const CalendarView = () => import("@/views/company/CalendarView");

const InvoicesView = () => import("@/views/ava/InvoicesView");
const NewInvoiceView = () => import("@/views/ava/NewInvoiceView");
const InvoiceView = () => import("@/views/ava/InvoiceView");
const InvoiceTemplatesView = () => import("@/views/ava/InvoiceTemplatesView");
const NewInvoiceTemplateView = () =>
  import("@/views/ava/NewInvoiceTemplateView");
const InvoiceTemplateView = () => import("@/views/ava/InvoiceTemplateView");
const NewEInvoicesView = () => import("@/views/ava/NewEInvoicesView");
const NewEInvoiceView = () => import("@/views/ava/NewEInvoiceView");

const WorkersView = () => import("@/views/company/WorkersView");
const WorkerView = () => import("@/views/company/WorkerView");
const NewWorkerView = () => import("@/views/company/NewWorkerView");
const WorkerBrigadesView = () => import("@/views/company/WorkerBrigadesView");
const WorkerAttributesView = () => import("@/views/workers/AttributesView");

const ResourcesView = () => import("@/views/company/ResourcesView");
const ResourceView = () => import("@/views/company/ResourceView");
const NewResourceView = () => import("@/views/company/NewResourceView");

const ProjectsView = () => import("@/views/company/ProjectsView");
const NewProjectView = () => import("@/views/company/NewProjectView");

const SuppliersView = () => import("@/views/company/SuppliersView");
const SupplierView = () => import("@/views/company/SupplierView");
const NewSupplierView = () => import("@/views/company/NewSupplierView");

const CostsView = () => import("@/views/company/CostsView");

const EquipmentListView = () => import("@/views/company/EquipmentListView");
const EquipmentDetailView = () => import("@/views/company/EquipmentDetailView");
const NewEquipmentView = () => import("@/views/company/NewEquipmentView");

const CompanySettingsView = () => import("@/views/company/CompanySettingsView");

const FilesView = () => import("@/views/company/FilesView");
const ClientsView = () => import("@/views/company/ClientsView");
const ClientView = () => import("@/views/company/ClientView");
const NewClient = () => import("@/views/company/NewClientView");
const ChatsView = () => import("@/views/company/ChatsView");

const OutgoingInvoicesView = () =>
  import("@/views/company/OutgoingInvoicesView");
const NewOutgoingInvoiceView = () =>
  import("@/views/company/NewOutgoingInvoiceView");
const NewMultipleOutgoingInvoicesView = () =>
  import("@/views/company/NewMultipleOutgoingInvoicesView.vue");
const OutgoingInvoiceView = () => import("@/views/ava/OutgoingInvoiceView");

const BankTransfersView = () => import("@/views/company/BankTransfersView.vue");
const NewBankTransferView = () =>
  import("@/views/company/NewBankTransferView.vue");

const SupplierGroupsView = () => import("@/views/ava/SupplierGroupsView");
const NewSupplierGroupView = () => import("@/views/ava/NewSupplierGroupView");
const SupplierGroupView = () => import("@/views/ava/SupplierGroupView");

const TasksView = () => import("@/views/company/TasksView");
const companyRoutes = [
  {
    name: "Calendar",
    path: "/calendar",
    component: CalendarView,
    meta: {
      public: false,
      sidebar: true,
      title: `Kalender | ${config.title}`,
    },
  },
  {
    name: "Invoices",
    path: "/invoices/incoming",
    component: InvoicesView,
    meta: {
      public: false,
      sidebar: true,
      title: `Arved | ${config.title}`,
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "AddInvoice",
    path: "/invoices/incoming/add",
    component: NewInvoiceView,
    meta: {
      public: false,
      sidebar: true,
      title: `Lisa arve | ${config.title}`,
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "AddOutgoingInvoice",
    path: "/invoices/outgoing/add/",
    component: NewOutgoingInvoiceView,
    meta: {
      public: false,
      sidebar: true,
      title: `Lisa arve | ${config.title}`,
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "NewMultipleOutgoingInvoicesView",
    path: "/invoices/outgoing/multiple/add/",
    component: NewMultipleOutgoingInvoicesView,
    meta: {
      public: false,
      sidebar: true,
      title: `Lisa arved | ${config.title}`,
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "InvoiceTemplates",
    path: "/invoices/incoming-templates",
    component: InvoiceTemplatesView,
    meta: {
      public: false,
      sidebar: true,
      title: `Ostuarvete mallid | ${config.title}`,
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "NewInvoiceTemplate`",
    path: "/invoices/incoming-templates/new",
    component: NewInvoiceTemplateView,
    meta: {
      public: false,
      sidebar: true,
      title: `Ostuarvete mallid | ${config.title}`,
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "NewEInvoices",
    path: "/Invoices/einvoices",
    component: NewEInvoicesView,
    meta: {
      public: false,
      sidebar: true,
      title: `Uued e-arved | ${config.title}`,
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "NewEInvoice",
    path: "/Invoices/einvoices/:invoiceId",
    component: NewEInvoiceView,
    meta: {
      public: false,
      sidebar: true,
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "OutgoingInvoicesView",
    path: "/invoices/outgoing",
    component: OutgoingInvoicesView,
    meta: {
      public: false,
      title: `Väljaminevad arved | ${config.title}`,
      sidebar: true,
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "Invoice",
    path: "/invoices/incoming/:invoiceId",
    component: InvoiceView,
    meta: {
      public: false,
      sidebar: true,
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "SentOutgoingInvoice",
    path: "/invoices/outgoing/confirmed/:invoiceId",
    component: SentOutgoingInvoiceView,
    meta: {
      public: false,
      sidebar: true,
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    name: "InvoiceTemplate",
    path: "/invoices/incoming-templates/:templateId",
    component: InvoiceTemplateView,
    meta: {
      public: false,
      sidebar: true,
      accountingPermissions: ["R3", "R1", "R2", "R0"],
    },
  },
  {
    path: "/workers",
    name: "Workers",
    component: WorkersView,
    meta: {
      public: false,
      title: `Töötajad | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/workers/new",
    name: "NewWorker",
    component: NewWorkerView,
    meta: {
      public: false,
      title: `Lisa uus töötaja | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/workers/brigades",
    name: "WorkerBrigades",
    component: WorkerBrigadesView,
    meta: {
      public: false,
      title: `Brigaadid | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/workers/attributes",
    name: "WorkerAttributes",
    component: WorkerAttributesView,
    meta: {
      public: false,
      title: `Oskused | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/workers/:id",
    name: "Worker",
    component: WorkerView,
    meta: {
      public: false,
      title: `Töötaja | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/resources",
    name: "Resources",
    component: ResourcesView,
    meta: {
      public: false,
      title: `Ressurssid | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/resources/new",
    name: "NewResource",
    component: NewResourceView,
    meta: {
      public: false,
      title: `Lisa uus ressurss | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/resources/:id",
    name: "Resource",
    component: ResourceView,
    meta: {
      public: false,
      title: `Ressurss | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/projects",
    name: "Projects",
    component: ProjectsView,
    meta: {
      public: false,
      title: `Tellimused | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/projects/new",
    name: "NewProject",
    component: NewProjectView,
    meta: {
      public: false,
      title: `Uus tellimus | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/suppliers",
    name: "Suppliers",
    component: SuppliersView,
    meta: {
      public: false,
      title: `Hankijad | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/suppliers/new",
    name: "NewSupplier",
    component: NewSupplierView,
    meta: {
      public: false,
      title: `Uus hankija | ${config.title}`,
      sidebar: true,
    },
  },

  {
    path: "/suppliers/groups/",
    name: "SupplierGroups",
    component: SupplierGroupsView,
    meta: {
      public: false,
      title: `Hankija grupid | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/suppliers/groups/new/",
    name: "NewSupplierGroup",
    component: NewSupplierGroupView,
    meta: {
      public: false,
      title: `Uus hankijate grupp | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/suppliers/groups/:groupId",
    name: "SupplierGroupView",
    component: SupplierGroupView,
    meta: {
      public: false,
      title: `Hankijate grupp | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/suppliers/:id",
    name: "Supplier",
    component: SupplierView,
    meta: {
      public: false,
      title: `Hankija | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/costs",
    name: "Costs",
    component: CostsView,
    meta: {
      public: false,
      title: `Kulud | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/equipment",
    name: "EquipmentList",
    component: EquipmentListView,
    meta: {
      public: false,
      title: `Tehnika | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/equipment/new",
    name: "NewEquipment",
    component: NewEquipmentView,
    meta: {
      public: false,
      title: `Uus tehnika | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/equipment/:id",
    name: "EquipmentDetail",
    component: EquipmentDetailView,
    meta: {
      public: false,
      title: `Tehnika | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/settings",
    name: "CompanySettings",
    component: CompanySettingsView,
    meta: {
      public: false,
      title: `Seaded | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/files",
    name: "Documents",
    component: FilesView,
    meta: {
      public: false,
      title: `Failid | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/clients",
    name: "Clients",
    component: ClientsView,
    meta: {
      public: false,
      title: `Kliendid | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/clients/new",
    name: "NewClient",
    component: NewClient,
    meta: {
      public: false,
      title: `Lisa klient | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/clients/:clientId",
    name: "Client",
    component: ClientView,
    meta: {
      public: false,
      title: `Klient | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/invoices/outgoing/:invoiceId",
    name: "OutgoingInvoice",
    component: OutgoingInvoiceView,
    meta: {
      public: false,
      title: `Väljaminev arve | ${config.title}`,
      sidebar: true,
      accountingPermissions: ["R0", "R1", "R2", "R3"],
    },
  },
  {
    path: "/invoices/generator/:projectId",
    name: "RevenueGeneratorView",
    component: RevenueGeneratorView,
    meta: {
      public: false,
      title: `Väljaminev arve | ${config.title}`,
      sidebar: false,
      accountingPermissions: ["R0", "R1", "R2", "R3"],
    },
  },
  {
    path: "/chats",
    name: "ChatsView",
    component: ChatsView,
    meta: {
      public: false,
      title: `Vestlused | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/tasks",
    name: "TasksView",
    component: TasksView,
    meta: {
      public: false,
      title: `Tööd | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/transfers",
    name: "BankTransfersView",
    component: BankTransfersView,
    meta: {
      public: false,
      title: `Laekumised | ${config.title}`,
      sidebar: true,
    },
  },
  {
    path: "/transfers/new",
    name: "NewBankTransferView",
    component: NewBankTransferView,
    meta: {
      public: false,
      title: `Uus laekumine | ${config.title}`,
      sidebar: true,
    },
  },
];

export default companyRoutes;
