<template>
  <div
    class="navbar-dropdown"
    :class="active ? 'navbar-dropdown-open' : 'navbar-dropdown-closed'"
    :style="active ? { height: '24rem', width: '20rem' } : null"
    v-on-clickaway="closeTray"
  >
    <transition-group
      name="fade"
      :duration="300"
      class="flex h-full w-full flex-col"
    >
      <div class="notification-card-container relative" v-if="active" key="1">
        <div class="p-2 border-b border-offwhite-dark bg-offwhite">
          <h4>Teavitused</h4>
        </div>
        <div class="flex-1 flex flex-col">
          <notification-card
            v-for="item in notifications"
            :key="item.id"
            :notification="item"
            @close="closeTray"
          />
        </div>
        <span v-if="notifications.length === 0" class="flex-1 p-2 mb-auto">
          Teavitusi pole!
        </span>
        <div
          v-if="notifications.length > 0"
          class="sticky bottom-0 bg-offwhite flex justify-center h-8"
        >
          <button class="btn-primary" @click="markAllRead">
            Märgi kõik loetuks
          </button>
        </div>
      </div>
      <div
        class="w-full mt-2 self-end rounded-b bottom-0"
        v-if="active"
        key="2"
      >
        <div class="flex flex-col rounded-md" />
      </div>
    </transition-group>
  </div>
</template>

<script>
import NotificationCard from "@/components/notifications/NotificationCard";
import { directive as onClickaway } from "vue-clickaway2";
import { mapGetters } from "vuex";
export default {
  props: {
    active: Boolean,
  },
  directives: {
    onClickaway,
  },
  methods: {
    closeTray() {
      this.$emit("closeTray");
    },
    markAllRead() {
      this.$store.dispatch("notifications/readAllUnread");
    },
  },
  components: {
    NotificationCard,
  },
  computed: {
    ...mapGetters({
      notifications: "notifications/unreadNotifications",
    }),
  },
};
</script>
