<template>
  <div class="modal">
    <div
      class="modal-body modal-medium mobile:w-full add-task-modal relative"
      v-on-clickaway:mousedown="closeModal"
    >
      <div class="modal-header px-2 py-1">
        <h3>
          {{ planningCost ? "Planeeri hankija kulu" : "Lisa hankija kulu" }}
        </h3>
      </div>
      <div class="modal-content flex-col mobile:w-full">
        <div
          class="flex mobile:flex-col mb-4"
          :class="task ? 'flex-col justify-between items-center' : 'flex-row'"
        >
          <add-supplier-cost
            class="mobile:w-full"
            ref="addSupplierCost"
            @close="closeModal"
            @supplierCostAdded="handleSaveSupplierCost"
            @formUpdated="handleFormStateChange"
            :supplier="supplier"
            :task="task ? task : selectedTask ? selectedTask.id : null"
            :planning-cost="planningCost"
            :project="selectedProject ? selectedProject.uuid : null"
            :tax-types="taxTypes"
          />
          <div v-if="!task" class="flex flex-col gap-y-2 mb-auto">
            <div class="flex mb-2 items-center justify-start">
              <gwd-formlabel title="Tellimus" class="mr-2" />
              <div v-if="!selectedProject">
                <button class="btn-primary" @click="addProject = true">
                  <span class="label">Vali tellimus</span>
                </button>
              </div>
            </div>
            <div
              v-if="selectedProject"
              class="flex flex-row justify-between mb-2"
            >
              <span>Valitud tellimus: {{ selectedProject.title }}</span>
              <div class="flex">
                <button class="btn-primary" @click="clearProject">
                  <span class="label">Muuda</span>
                </button>
              </div>
            </div>
            <div class="flex flex-col my-2" v-if="selectedProject">
              <gwd-formlabel title="Tellimuse tööd" />
              <div
                class="flex flex-row items-center justify-between"
                v-if="selectedTask"
              >
                <span class="label">Valitud töö: {{ selectedTask.title }}</span>
                <button class="btn-primary" @click="selectedTask = null">
                  <span class="label">Muuda</span>
                </button>
              </div>
              <div
                class="flex flex-wrap flex-col overflow-y-hidden overflow-x-scroll"
                v-else
              >
                <div
                  v-for="projectTask in projectTasks"
                  :key="projectTask.id"
                  class="p-3 cursor-pointer w-full border flex flex-row justify-between items-center rounded-gwdhalf mobile:w-full duration-100 mb-1"
                  :class="
                    selectedTask && projectTask.id === selectedTask.id
                      ? 'shadow  border-primary'
                      : 'border-transparent'
                  "
                  @click="handleTaskSelection(projectTask)"
                >
                  <h4 class="mr-2 truncate">{{ projectTask.title }}</h4>
                  <span
                    class="ml-2"
                    v-html="parseTaskStatus(projectTask.status)"
                  >
                  </span>
                </div>
                <div v-if="projectTasks.length === 0">
                  Tellimusel puuduvad tööd.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button
          @click="saveSupplierCost"
          class="btn-primary mr-3"
          :class="formIsValid ? 'btn-primary' : 'btn-disabled'"
        >
          <span class="icon typcn typcn-tick"></span>
          <span class="label">Salvesta</span>
        </button>
        <button class="new-button-danger" @click="closeModal">
          <span class="typcn typcn-times icon"></span>
          <span class="label">Sulge</span>
        </button>
      </div>
      <project-selector
        v-if="addProject"
        @closeModal="addProject = false"
        @projectAdded="selectProject"
        :convert="false"
        class="z-999"
      />
    </div>
  </div>
</template>

<script>
import { EventBus, StatusParser } from "../../../assets/mixins/GewodoMixins";
import AddSupplierCost from "./AddSupplierCost";
import ProjectSelector from "@/components/tasks/ProjectSelector.vue";
import { mapGetters } from "vuex";

export default {
  name: "AddSupplierCostModal",
  components: { ProjectSelector, AddSupplierCost },
  props: {
    supplier: {
      type: Object,
      default: () => {
        return {};
      },
    },
    task: {
      type: Number,
      default: null,
    },
    planningCost: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addProject: false,
      selectedProject: null,
      projectTasks: [],
      selectedTask: null,
      formIsValid: false,
    };
  },
  mixins: [StatusParser],

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    saveSupplierCost() {
      this.$refs.addSupplierCost.saveSupplierCost();
    },
    handleSaveSupplierCost() {
      EventBus.$emit("supplierCostAdded");
      this.closeModal();
    },
    handleFormStateChange(event) {
      this.formIsValid = event;
    },
    clearProject() {
      this.selectedProject = null;
      this.selectedTask = null;
      this.projectTasks = [];
    },
    selectProject(e) {
      this.selectedProject = e;
      this.loadProjectTasks(e);
    },
    loadProjectTasks(project) {
      this.apiRequest(
        `calendar/${this.companyId}/${project.uuid}/tasks/?period=all`,
        "get",
        true
      ).then((res) => {
        this.projectTasks = res.data;
      });
    },
    handleTaskSelection(e) {
      this.selectedTask = e;
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      taxTypes: "companyVariables/taxTypes",
    }),
  },
  async mounted() {
    if (!this.taxTypes)
      await this.$store.dispatch("companyVariables/retrieveTaxTypes");

    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
};
</script>
