<template>
  <div class="gwd-table-row supplier-cost">
    <slot></slot>
    <span>{{ cost.name }}</span>
    <div class="flex justify-end items-center gap-x-1" v-if="edit">
      <decimal-input v-model.number="editables.amount" class="ml-auto" />
      <select class="add-offer-input" v-model="editables.unit">
        <option v-for="unit in units" :value="unit" :key="unit">
          {{ unit }}
        </option>
      </select>
    </div>
    <span v-else> {{ cost.amount }} {{ cost.unit }} </span>
    <div class="flex">
      <span v-if="!edit">{{ cost.cost }} €</span>
      <decimal-input v-else v-model="editables.cost" />
    </div>
    <div class="flex">
      <span v-if="!edit">{{ cost.price }} €</span>
      <decimal-input v-else v-model="editables.price" />
    </div>
    <span v-if="!edit">{{
      editables.vatType ? editables.vatType.description : "-"
    }}</span>
    <select v-else v-model="editables.vatType">
      <option
        v-for="taxType in taxTypes.filter((x) => x.outgoing_tax_code)"
        :key="taxType.id"
        :value="taxType"
      >
        {{ `${taxType.description}` }}
      </option>
    </select>
    <span>{{ round2(cost.price * cost.amount) }} {{ cost.currency }}</span>
    <div
      v-if="isAdmin || isManager"
      class="flex justify-end items-center gap-x-2"
    >
      <button v-if="!edit" class="btn-primary" @click="edit = true">
        <span class="typcn typcn-edit icon" />
      </button>
      <button v-if="edit" class="btn-primary" @click="editCost">
        <span class="typcn typcn-tick" />
      </button>
      <button v-if="edit" class="btn-danger" @click="resetCost">
        <span class="typcn typcn-times" />
      </button>
      <button
        v-if="(isAdmin || isManager) && edit"
        class="btn-danger"
        @click="deleteCostActive = true"
      >
        <img
          src="/bc21/trash.svg"
          class="filter-to-white h-5 w-5"
          alt="Delete cost"
        />
      </button>
    </div>
    <delete-confirm-modal
      v-if="deleteCostActive"
      @confirmDelete="deleteCost"
      @closeModal="deleteCostActive = false"
    />
  </div>
</template>

<script>
import { round2 } from "@/assets/utils/commonMath";
import { isEqual } from "lodash";
import DecimalInput from "@/components/reusable/DecimalInput.vue";
import { mapGetters } from "vuex";
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal.vue";

export default {
  name: "CompactCost",
  components: { DeleteConfirmModal, DecimalInput },
  props: {
    cost: {
      type: Object,
      default: null,
    },
    taxTypes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      edit: false,
      editables: null,
      deleteCostActive: false,
    };
  },
  methods: {
    round2,
    editCost() {
      if (!isEqual(this.editables, this.cost)) {
        if (this.editables.vatType)
          this.editables.vat = this.editables.vatType.id;
        this.$emit("editCost", this.editables);
      }
      this.edit = false;
    },
    deleteCost() {
      this.$emit("deleteCost", this.editables);
      this.edit = false;
    },
    resetCost() {
      this.editables = JSON.parse(JSON.stringify(this.cost));
      this.edit = false;
    },
  },
  mounted() {
    this.editables = JSON.parse(JSON.stringify(this.cost));
  },
  computed: {
    ...mapGetters({
      units: "companyVariables/units",
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
    }),
  },
};
</script>
<style scoped lang="scss">
input {
  width: 95%;
}
select {
  width: 75%;
  option {
    width: 75%;
  }
}
</style>
