<template>
  <div class="gwd-table nested h-full" v-if="taskExtraCostsLoaded">
    <div class="gwd-table-header">Lisatööd</div>
    <div
      class="gwd-table-header"
      :class="
        isAdmin || isManager ? 'extra-cost-table' : 'extra-cost-table-worker'
      "
    >
      <span class="mobile:hidden">Töötaja</span>
      <span>Kirjeldus</span>
      <span>Kogus</span>
      <span>{{ isManager ? "Kulu" : "Ühiku hind" }}</span>
      <span v-if="isAdmin || isManager">Koefitsent</span>
      <span v-if="isAdmin || isManager">Kulu kokku</span>
      <span v-if="isAdmin || isManager">Müügihind</span>
      <span v-if="isAdmin || isManager">KM</span>
      <span>Lisainfo</span>
      <span>Kokku</span>
      <span>Kinnitatud</span>
    </div>
    <div class="gwd-table-row-wrapper mobile:overflow-x-hidden">
      <div
        class="gwd-table-row"
        :class="
          isAdmin || isManager ? 'extra-cost-table' : 'extra-cost-table-worker'
        "
        v-for="extraWorkPlanning in extraWorkPlannings"
        :key="extraWorkPlanning.id"
      >
        <span class="mobile:hidden">Planeeritud töö</span>
        <span>
          <span class="desktop:hidden">Planeeritud töö:</span>
          {{ extraWorkPlanning.name }}
        </span>
        <span>
          {{ extraWorkPlanning.amount }} {{ extraWorkPlanning.unit }}
        </span>
        <span v-if="isAdmin || isManager">{{ extraWorkPlanning.cost }} €</span>
        <span v-if="isAdmin || isManager">
          {{ extraWorkPlanning.cost_coefficient }}
        </span>
        <span v-if="isAdmin || isManager">
          {{
            round2(extraWorkPlanning.cost * extraWorkPlanning.cost_coefficient)
          }}
          €
        </span>
        <span v-else>-</span>
        <span v-if="isAdmin || isManager">{{ extraWorkPlanning.price }} €</span>
        <span v-if="isAdmin || isManager">
          {{
            extraWorkPlanning.vatType
              ? `${extraWorkPlanning.vatType.description}`
              : "-"
          }}
        </span>
        <span />
        <span v-if="isAdmin || isManager">
          {{ round2(extraWorkPlanning.amount * extraWorkPlanning.price) }} €
        </span>
        <span />
        <div v-if="isAdmin || isManager" class="flex justify-end">
          <button
            class="btn-danger"
            @click="planningToDelete = extraWorkPlanning"
          >
            <img
              src="/bc21/trash.svg"
              class="filter-to-white h-5 w-5"
              alt="Delete assignment"
            />
          </button>
        </div>
      </div>
      <div
        class="gwd-table-header nopad"
        v-if="extraWorkPlannings.length > 0"
      />
      <extra-cost
        v-for="extraCost in taskExtraCosts"
        :key="extraCost.id"
        :extra-cost="extraCost"
        :tax-types="taxTypes"
        @confirmExtraCost="confirmWorkerExtraCost"
        @editCost="editCost"
        @deleteCost="deleteCost"
        :is-admin="isAdmin"
        :is-manager="isManager"
      />
      <span
        v-if="taskExtraCosts.length === 0 && extraWorkPlannings.length === 0"
        class="p-2"
      >
        Määratud lisatöid pole.
      </span>
    </div>

    <div class="gwd-table-bottom-bar">
      <button
        class="btn-primary mr-3"
        v-if="['R0', 'R1', 'R2'].includes(userPermissions)"
        @click="$store.commit('modals/taskModal/openAddExtraCostModal', true)"
      >
        Planeeri lisatöö
      </button>

      <button
        class="btn-primary"
        @click="$store.commit('modals/taskModal/openAddExtraCostModal', false)"
      >
        <span class="label">Lisa kulu</span>
      </button>
    </div>
    <delete-confirm-modal
      v-if="planningToDelete"
      @confirmDelete="deletePlannedCost"
      @closeModal="planningToDelete = null"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { EventBus } from "../../../assets/mixins/GewodoMixins";
import { round2 } from "@/assets/utils/commonMath";
import ExtraCost from "@/components/tasks/costs/ExtraCost.vue";
import set from "lodash/set";
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal.vue";

export default {
  name: "TaskExtraCostAssignments",
  components: { DeleteConfirmModal, ExtraCost },
  props: {
    taskId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      taskExtraCosts: [],
      taskExtraCostsLoaded: false,
      extraWorkPlannings: [],
      planningToDelete: false,
    };
  },
  methods: {
    round2,
    loadTaskExtraCosts() {
      this.apiRequest(
        `template/${this.companyId}/extras/?task=${this.taskId}`,
        "get",
        true
      ).then((res) => {
        this.extraWorkPlannings = res.data;
        this.$store.dispatch("modals/updateCheckModal/initData", {
          name: "extraCostPlanning",
          retrieveUrl: `template/${this.companyId}/extras/?task=${this.taskId}`,
          startingData: JSON.parse(JSON.stringify(res.data)),
        });
        for (let extraCostPlan of this.extraWorkPlannings) {
          if (extraCostPlan.vat) {
            set(
              extraCostPlan,
              "vatType",
              this.taxTypes.find((x) => x.id === extraCostPlan.vat)
            );
          }
        }
      });
      this.apiRequest(
        `costs/${this.companyId}/workers/costs/?task=${this.taskId}`,
        "get",
        true
      ).then((res) => {
        this.taskExtraCosts = res.data;
        this.$store.dispatch("modals/updateCheckModal/initData", {
          name: "extraCost",
          retrieveUrl: `costs/${this.companyId}/workers/costs/?task=${this.taskId}`,
          startingData: JSON.parse(JSON.stringify(res.data)),
        });
        for (let extraCost of this.taskExtraCosts) {
          if (extraCost.vat) {
            set(
              extraCost,
              "vatType",
              this.taxTypes.find((x) => x.id === extraCost.vat)
            );
          }
        }
        this.taskExtraCostsLoaded = true;
      });
    },
    confirmWorkerExtraCost(cost) {
      this.apiRequest(
        `costs/${this.companyId}/worker/${cost.worker.id}/extra/${cost.id}/confirm/`,
        "post",
        true
      ).then((res) => {
        if (res.status === 200) {
          this.loadTaskExtraCosts();
        }
      });
    },
    editCost(e) {
      this.apiRequest(
        `costs/${this.companyId}/costs/extraCost/${e.id}/edit/`,
        "patch",
        true,
        e
      )
        .then((res) => {
          if (res.status === 200) {
            this.loadTaskExtraCosts();
          }
        })
        .catch((err) =>
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            error: err,
            text: "Midagi läks valesti",
            ttl: 5,
          })
        );
    },
    deleteCost(e) {
      this.apiRequest(
        `costs/${this.companyId}/costs/extraCost/${e.id}/delete/`,
        "delete",
        true
      )
        .then((res) => {
          if (res.status === 200) {
            this.loadTaskExtraCosts();
          }
        })
        .catch((err) =>
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            error: err,
            text: "Kulu kustutamine ebaõnnestus!",
            ttl: 5,
          })
        );
    },
    deletePlannedCost() {
      if (!this.planningToDelete) return;
      this.apiRequest(
        `template/${this.companyId}/extras/task/${this.taskId}/${this.planningToDelete.id}/delete/`,
        "delete",
        true
      ).then((res) => {
        if (res.status === 200) this.loadTaskExtraCosts();
      });
    },
  },
  async mounted() {
    if ((this.isAdmin || this.isManager) && !this.taxTypes)
      await this.$store.dispatch("companyVariables/retrieveTaxTypes");
    this.loadTaskExtraCosts();
    EventBus.$on("costSaved", () => this.loadTaskExtraCosts());
  },
  beforeDestroy() {
    EventBus.$off("costSaved");
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
      userPermissions: "companyData/userPermission",
      taxTypes: "companyVariables/taxTypes",
    }),
  },
};
</script>
<style lang="scss">
.extra-cost-table {
  //                     worker name amount cost cost_co tot price vat  info  sum  confirm delete
  grid-template-columns: 0.3fr 0.5fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.3fr 0.5fr 0.2fr 0.3fr 0.1fr;
}
.extra-cost-table-worker {
  //                     worker name price amount sum info   confirmed
  grid-template-columns: 0.3fr 0.4fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr;

  @media screen and (max-width: 992px) {
    //                     name amount price sum info confirmed
    grid-template-columns: 0.4fr 0.1fr 0.1fr 0.15fr 0.2fr 0.1fr;
  }
  @media screen and (max-width: 601px) {
    @apply flex-wrap m-1 border-b border-b-offwhite-dark w-full;
    grid-template-columns: 0.4fr 0.3fr 0.3fr;
  }
}
</style>
