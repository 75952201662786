import StoreRequestHandler from "@/assets/mixins/StoreRequestHandler";
import { isEqual } from "lodash";

function getInitialState() {
  return {
    show: false,
    data: null,
    conflicts: [],
  };
}

export const updateCheckModal = {
  namespaced: true,
  state: {
    show: false,
    data: null,
    conflicts: [],
  },
  mutations: {
    openModal(state, data) {
      state.show = true;
      state.data = data;
    },
    initData(state, data) {
      let existingConflict = state.conflicts.find((x) => x.name === data.name);
      if (existingConflict)
        state.conflicts.splice(state.conflicts.indexOf(existingConflict), 1);
      state.conflicts.push(data);
    },
    closeModal(state, name) {
      if (state.conflicts.length === 1) Object.assign(state, getInitialState());
      let existingConflict = state.conflicts.find((x) => x.name === name);
      if (existingConflict)
        state.conflicts.splice(state.conflicts.indexOf(existingConflict), 1);
      state.show = false;
    },
  },
  actions: {
    initData({ commit }, data) {
      commit("initData", data);
    },
    closeModal({ commit }, name) {
      commit("closeModal", name);
    },
    async compareData({ state, dispatch, commit }, data) {
      let conflict = state.conflicts.find((x) => x.name === data.name);
      if (conflict === undefined) return true;
      if (!conflict.retrieveUrl) return true;
      return await StoreRequestHandler.apiRequest(
        conflict.retrieveUrl,
        "get",
        true
      ).then((res) => {
        const databaseData = res.data;
        if (!isEqual(databaseData, conflict.startingData)) {
          conflict.databaseData = JSON.parse(JSON.stringify(databaseData));
          dispatch(
            "messageHandler/throwMessage",
            {
              type: "error",
              text: "Andmebaasi ning kohalike andmete viga.",
              ttl: 15,
            },
            { root: true }
          );
          commit("openModal", data);
          return false;
        } else {
          return true;
        }
      });
    },
  },
  getters: {
    conflicts: (state) => state.conflicts,
    postData: (state) => state.data,
  },
};
