<template>
  <div class="flex flex-col gap-y-2 text-sm">
    <div class="flex justify-between">
      <gwd-formlabel title="Valitud sildid" />
      <gwd-formlabel title="Lisa uus silt" />
    </div>
    <div class="flex flex-row w-full items-center justify-between">
      <div class="flex gap-x-1">
        <draggable
          v-model="taskObject.tags"
          v-bind="dragOptions"
          class="flex flex-row items-center gap-x-1"
        >
          <div
            v-for="(selectedTag, index) in taskObject.tags"
            :key="index"
            class="px-1 bg-primary rounded duration-100 text-white cursor-pointer"
            @click="
              taskObject.tags.splice(taskObject.tags.indexOf(selectedTag), 1)
            "
          >
            <span> {{ selectedTag }}</span>
            <span class="typcn typcn-times" />
          </div>
        </draggable>

        <div v-if="taskObject.tags.length === 0">
          <span class="p-1">Valitud puuduvad</span>
        </div>
      </div>
      <div class="flex ml-auto">
        <input type="text" v-model="newTag" @keydown.enter="addCustomTag" />
        <span
          class="typcn typcn-arrow-right-outline h-6 w-6 self-center ml-1 -mr-1 cursor-pointer"
          @click="addCustomTag"
        />
      </div>
    </div>
    <gwd-formlabel
      v-if="existingTags && existingTags.length > 0"
      title="Ennem kasutatud sildid"
    />
    <div
      v-if="existingTags && existingTags.length > 0"
      class="flex items-center gap-x-1"
    >
      <div
        class="px-1 rounded duration-100 text-white"
        @click="handleTagSelected(existingTag)"
        v-for="existingTag in existingTags.filter(
          (x) => !taskObject.tags.includes(x)
        )"
        :key="existingTag"
        :class="
          existingTag.includes('-') &&
          (existingTag
            .split('-')
            .map((x) => x.toLowerCase())
            .includes(startCity) ||
            existingTag
              .split('-')
              .map((x) => x.toLowerCase())
              .includes(endCity))
            ? 'bg-green'
            : 'bg-primary'
        "
      >
        {{ existingTag }}
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { mapGetters, mapState } from "vuex";

export default {
  name: "AddTaskTagSelector",
  components: { draggable },
  props: {
    existing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTags: [],
      newTag: "",
    };
  },
  mounted() {
    this.handleTagSearch();
  },
  methods: {
    addCustomTag() {
      this.handleTagSelected(this.newTag);
      this.newTag = "";
    },
    handleTagSelected(tag) {
      if (!this.taskObject.tags.includes(tag)) {
        this.taskObject.tags.push(tag);
      } else {
        this.taskObject.tags.splice(
          this.taskObject.tags.indexOf(
            this.taskObject.tags.find((x) => x === tag)
          ),
          1
        );
      }
    },
    handleTagSearch() {
      if (this.startCity && this.endCity) {
        const foundTags = this.existingTags
          .filter((x) => x.includes("-"))
          .filter(
            (x) =>
              x.split("-")[0].toLowerCase() === this.startCity &&
              x.split("-")[x.split("-").length - 1].toLowerCase() ===
                this.endCity
          );
        for (let tag of foundTags) {
          this.handleTagSelected(tag);
        }
      }
    },
  },
  computed: {
    ...mapState({
      fields: (state) => state.modals.addTaskModal.fields,
      taskData: (state) => state.modals.taskModal.taskData,
    }),
    ...mapGetters({
      existingTags: "companyVariables/tags",
    }),
    taskObject() {
      return this.existing ? this.taskData : this.fields;
    },
    startCity() {
      if (this.taskObject.location && this.taskObject.location.city) {
        return this.taskObject.location.city.split(" ")[0].toLowerCase();
      }
      return null;
    },
    endCity() {
      const endLocation = this.existing
        ? this.taskObject.end_location
        : this.taskObject.endLocation;
      if (endLocation && endLocation.city) {
        return endLocation.city.split(" ")[0].toLowerCase();
      }
      return null;
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  watch: {
    "fields.location"() {
      this.handleTagSearch();
    },
    "fields.endLocation"() {
      this.handleTagSearch();
    },
  },
};
</script>

<style scoped lang="scss"></style>
