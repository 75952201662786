<template>
  <div id="app" class="bg-offwhite relative">
    <div :class="$route.meta.sidebar ? '' : 'sticky top-0 z-50'">
      <page-navbar />
    </div>
    <div
      class="wrapper flex mobile:flex-col w-full flex-grow"
      :class="$store.state.modals.taskModal.show ? 'overflow-y-hidden' : ''"
    >
      <sidebar-overlord
        class="w-36 mobile:w-full"
        v-if="
          $route.meta.sidebar &&
          ((authStatus.loggedIn && $store.state.companyData.retrieved) ||
            !authStatus.loggedIn)
        "
        @activeCompanyChanged="handleActiveCompanyChange"
      />
      <transition name="fade" mode="out-in" :duration="50">
        <router-view
          v-if="
            dataRetrieved &&
            ((authStatus.loggedIn && $store.state.companyData.retrieved) ||
              !authStatus.loggedIn)
          "
          class="overflow-y-auto"
          :class="[
            [
              'Home',
              'Landing',
              'AboutUs',
              'Product',
              'Pricing',
              'Demo',
            ].includes($route.name)
              ? 'p-0'
              : 'px-6 py-6 mobile:px-0 mobile:py-0',
            $route.meta.sidebar
              ? 'flex-grow mobile:w-full medium:w-3/4 sidebar-width-limiter'
              : 'w-full',
            anyModalOpen ? 'overflow-y-hidden' : '',
          ]"
        >
        </router-view>
      </transition>
    </div>
    <message-handler />
    <transition name="fade" :duration="100">
      <loading-modal v-if="$store.state.loading"></loading-modal>
    </transition>
    <modal-controller />
    <chat-controller
      v-if="
        config.features.chat && $store.state.chat.activeChatParties.length > 0
      "
    />
    <timer-bar
      v-if="$store.state.timetracking.hasActiveTimer && screenW < 993"
    />
  </div>
</template>

<script>
import PageNavbar from "@/components/PageNavbar";
import LoadingModal from "@/components/LoadingModal";
import SidebarOverlord from "@/components/SidebarOverlord";
import ModalController from "@/components/reusable/ModalController";
import TimerBar from "@/components/reusable/TimerBar";
import MessageHandler from "./components/MessageHandler.vue";
import { mapGetters } from "vuex";
import ChatController from "@/components/chat/ChatController";
import config from "../gewodo.config.json";
export default {
  name: "App",
  components: {
    ChatController,
    PageNavbar,
    LoadingModal,
    SidebarOverlord,
    ModalController,
    TimerBar,
    MessageHandler,
  },
  created() {
    document.documentElement.setAttribute("lang", "et");
  },
  data() {
    return {
      showCookieBanner: false,
      config: config,
    };
  },
  async mounted() {
    document.title = this.$route.meta.title ?? this.config.title;
    if (!this.$store.state.auth.loggedIn) return;
    if ("serviceWorker" in navigator) {
      await navigator.serviceWorker
        .register("/service-worker.js")
        .catch((err) => {
          console.log(err);
        });
    }
    this.$store
      .dispatch("auth/verifyToken", true)
      .catch(() => {
        this.$store
          .dispatch("auth/refreshToken", true)
          .then((res) => {
            if (res.status !== 200) this.logout();
          })
          .catch(() => this.logout());
      })
      .then(async () => {
        if (!this.$store.state.companyData.retrieved)
          await this.$store.dispatch("companyData/retrieveCompanyData");
        this.$store.dispatch("notifications/retrieveNotifications");
        this.$store.dispatch("timetracking/retrieveTimers");
        if (!this.taxTypes)
          this.$store.dispatch("companyVariables/retrieveTaxTypes");
        if (!this.costAccounts)
          this.$store.dispatch("companyVariables/retrieveCostAccounts");
        if (!this.serviceObjects && this.config.features.chat)
          this.$store.dispatch("companyVariables/retrieveServiceObjects");
        if (this.config.features.calendar && !this.companyWorkers)
          await this.$store.dispatch("companyVariables/retrieveWorkers");
      });
  },
  methods: {
    handleActiveCompanyChange() {
      if (this.$route.name !== "Calendar")
        this.$router.push(this.config.homepage);
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  computed: {
    dataRetrieved() {
      if (this.$store.state.auth.status.loggedIn)
        return this.$store.state.userData.retrieved;
      return true;
    },
    screenW() {
      return window.innerWidth;
    },
    ...mapGetters({
      anyModalOpen: "anyModalOpen",
      taxTypes: "companyVariables/taxTypes",
      costAccounts: "companyVariables/costAccounts",
      serviceObjects: "companyVariables/serviceObjects",
      companyWorkers: "companyVariables/companyWorkers",
      authStatus: "auth/status",
      errors: "messageHandler/messages",
    }),
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || this.config.title;
    },
    anyModalOpen() {
      if (this.anyModalOpen) {
        document
          .getElementsByTagName("body")[0]
          .classList.add("overflow-y-hidden");
      } else {
        document
          .getElementsByTagName("body")[0]
          .classList.remove("overflow-y-hidden");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-width-limiter {
  max-width: calc(100vw - 9rem);
}
@media (max-width: 1024px) {
  .sidebar-width-limiter {
    max-width: 100%;
  }
}
</style>
