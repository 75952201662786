<template>
  <div class="modal">
    <div class="modal-body modal-medium" v-on-clickaway:mousedown="closeModal">
      <div class="modal-header p-3">
        <h4>Saada uus meil</h4>
      </div>
      <div class="modal-content flex-col w-full" v-if="inited">
        <div class="flex flex-row w-full items-center mb-2">
          <input type="text" placeholder="Teema" v-model="title" />
          <div
            v-if="!sendingInfo.e_invoice_sent"
            class="flex ml-auto items-center"
          >
            <span>Saada E-arve</span>
            <gwd-checkbox v-model="sendEInvoice" />
          </div>
        </div>
        <div class="flex flex-row w-full items-center mb-2">
          <span class="mr-2">Saaja</span>
          <div class="flex">
            <div
              v-for="(email, index) in emails"
              :key="index"
              class="flex bg-offwhite rounded-gwdhalf p-1 items-center"
            >
              <span> {{ email }}</span>
              <span
                class="typcn typcn-times cursor-pointer"
                @click="emails.splice(emails.indexOf(email), 1)"
              />
            </div>
          </div>
          <input type="email" v-model="newEmail" @keydown.enter="addToEmails" />
        </div>
        <div class="flex w-full">
          <gwd-textarea
            v-model="contentText"
            class="w-full"
            :background="true"
          />
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button class="btn-primary mr-3" @click="sendNewEmail">
          Saada meil
        </button>
        <button class="btn-danger" @click="closeModal">Sulge</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmailSendingModal",
  props: {
    sendingInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      inited: false,
      emails: [],
      title: "",
      contentText: "",
      newEmail: "",
      sendEInvoice: false,
    };
  },
  mounted() {
    this.emails = this.sendingInfo.email_addresses.split(";");
    this.title = this.sendingInfo.email_title;
    this.contentText = this.sendingInfo.email_body;
    document.body.classList.add("modal-open");
    this.inited = true;
  },
  methods: {
    validateEmail(email) {
      const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
      return reg.test(email);
    },
    addToEmails() {
      if (this.newEmail.length > 0 && this.validateEmail(this.newEmail)) {
        this.emails.push(this.newEmail);
        this.newEmail = "";
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    sendNewEmail() {
      this.$emit("sendNewEmail", {
        email_addresses: this.emails,
        message_text: this.contentText,
        title: this.title,
        send_e_invoice: this.sendEInvoice,
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
