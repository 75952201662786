<template>
  <nav
    class="w-full flex h-navbar z-40 bg-white shadow mobile:px-0 relative font-body"
    :class="
      !$store.state.userData.user.is_email_verified &&
      $store.state.auth.status.loggedIn &&
      this.$route.name !== 'ResendActivation'
        ? 'mb-16'
        : ''
    "
  >
    <div class="w-full flex mx-auto flex-row mobile:py-3 mobile:mx-0 relative">
      <div
        class="w-36 mobile:hidden flex mobile:justify-between justify-center items-center mobile:px-3"
        :class="
          $store.state.auth.status.loggedIn ? 'mobile:w-1/2' : 'mobile:w-full'
        "
      >
        <router-link
          :to="logoLink"
          :class="
            logoLink === $route.path ? 'cursor-default' : 'cursor-pointer'
          "
        >
          <img
            src="/logo.png"
            alt="Gewodo logo"
            class="mobile:h-12 h-16 p-3 mobile:p-1 mobile:hidden"
          />
        </router-link>
        <button
          class="h-10 w-10 duration-100 hidden mobile:flex"
          :class="
            hamburgerActive
              ? 'bg-white shadow-dangeroutline focus:bg-white text-danger'
              : 'bg-primary text-white focus:text-primary focus:bg-white focus:shadow-outline'
          "
          v-if="
            !this.$store.state.auth.status.loggedIn && !this.$route.meta.isProto
          "
          @click="hamburgerActive = !hamburgerActive"
        >
          <span
            class="typcn"
            :class="
              hamburgerActive ? 'text-3xl typcn-times' : 'typcn-th-menu text-xl'
            "
          />
        </button>
      </div>
      <div class="flex"></div>
      <div
        class="flex justify-start flex-grow mobile:hidden medium:self-center items-center gap-x-4"
      >
        <div
          class="flex"
          v-if="
            $store.state.auth.status.loggedIn &&
            config.features.tasks &&
            ['R0', 'R1', 'R2'].includes(userRole)
          "
        >
          <button
            class="btn-primary"
            @click="
              () => {
                $store.dispatch('modals/addTaskModal/open', addTaskDataInput);
              }
            "
            tabindex="1"
          >
            Uus töö
          </button>
        </div>
        <div
          class="flex"
          v-if="
            $store.state.auth.status.loggedIn &&
            config.features.tasks &&
            ['R0', 'R1', 'R2'].includes(userRole)
          "
        >
          <button
            class="btn-primary"
            @click="
              () => {
                $store.dispatch('modals/taskPlanningModal/open', {});
              }
            "
            tabindex="2"
          >
            Planeeri töö
          </button>
        </div>
        <div
          class="flex"
          v-if="
            $store.state.auth.status.loggedIn &&
            config.features.projects &&
            ['R0', 'R1', 'R2'].includes(userRole)
          "
        >
          <router-link to="/projects/new">
            <button class="btn-primary" tabindex="3">Uus tellimus</button>
          </router-link>
        </div>
        <transition name="fade" :duration="100" mode="out-in">
          <timer-component
            v-if="
              $store.state.timetracking.hasActiveTimer &&
              !$store.state.timetracking.loading
            "
          />
        </transition>
      </div>
      <div
        class="w-4/12 mobile:w-full flex justify-end items-center flex-row mobile:px-3 pr-6"
        v-if="
          $store.state.auth.status.loggedIn && $store.state.userData.retrieved
        "
      >
        <div class="flex mr-3 flex-grow">
          <div
            class="flex bg-offwhite w-full py-1 px-3 justify-between relative shadow cursor-pointer"
            :class="showCompanySelector ? 'rounded-t' : 'rounded'"
            @click="showCompanySelector = !showCompanySelector"
            v-if="activeCompany"
          >
            <div class="truncate max-w-full flex items-center">
              <span>{{ activeCompany.company_name }}</span>
            </div>
            <span
              class="typcn typcn-arrow-sorted-down transform duration-100"
              :class="showCompanySelector ? 'rotate-180' : ''"
            ></span>
            <div class="company-selector shadow" v-if="showCompanySelector">
              <div
                v-for="(item, index) in companies"
                :key="item.uuid"
                class="flex justify-between items-center hover:bg-offwhite-dark"
                :class="index === companies.length - 1 ? 'rounded-b' : ''"
                @click.stop.prevent="changeActiveCompany(item.uuid)"
              >
                <span>{{ item.company_name }}</span>
                <div class="flex ml-auto items-center">
                  <span
                    v-if="
                      item.uuid === $store.state.userData.user.default_company
                    "
                    class="flex rounded-full justify-center typcn typcn-star bg-attention h-6 w-6"
                  />
                  <span
                    v-else
                    @click.stop.prevent="setCompanyAsDefault(item)"
                    class="flex rounded-full justify-center typcn typcn-star-outline h-6 w-6"
                  />
                  <span
                    class="rounded-full h-4 w-4 border-2 border-primary flex items-center justify-center ml-2"
                  >
                    <span
                      v-if="item.uuid === activeCompany.uuid"
                      class="bg-primary h-2 w-2 rounded-full"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end relative">
          <div
            v-if="config.features.chat && $route.name !== 'ChatsView'"
            class="mr-2 group navbar-button"
            @click="
              chatTrayActive ? setChatTrayState(false) : setChatTrayState(true)
            "
            tabindex="4"
          >
            <img
              class="h-6 w-6 group-hover:filter-to-white cursor-pointer"
              src="/icons/messages.svg"
              alt="Messages icon"
            />
          </div>
          <span
            role="button"
            v-if="
              config.features.chat &&
              $store.state.chat.chatNotifications.length > 0 &&
              $route.name !== 'ChatsView'
            "
            class="absolute notification-number flex justify-center items-center text-white font-semibold cursor-pointer"
            >{{ $store.state.chat.chatNotifications.length }}</span
          >
        </div>
        <div class="flex items-center justify-end relative">
          <div
            class="mr-2 group navbar-button"
            @click="
              notificationTrayActive
                ? setNotificationTrayState(false)
                : setNotificationTrayState(true)
            "
            tabindex="5"
          >
            <img
              class="h-6 w-6 group-hover:filter-to-white cursor-pointer"
              src="/bc21/bell.svg"
              alt="Messages icon"
            />
          </div>
          <span
            role="button"
            v-if="
              $store.state.notifications.notificationsLoaded &&
              $store.state.notifications.unread.length > 0
            "
            class="absolute notification-number flex justify-center items-center text-white font-semibold cursor-pointer"
            >{{ $store.state.notifications.unread.length }}</span
          >
        </div>
        <router-link
          to="/settings"
          v-if="
            activeCompany &&
            config.features.settings &&
            ['R0', 'R1', 'R2'].includes(userRole)
          "
          class="mr-2 group navbar-button"
          tabindex="6"
        >
          <img
            src="/icons/setting.svg"
            alt="Settings icon"
            class="h-6 w-6 group-hover:filter-to-white"
          />
        </router-link>
        <div class="flex items-center justify-end">
          <div
            class="flex relative cursor-pointer duration-100"
            @click="toggleUserMenu"
            tabindex="7"
          >
            <img
              v-if="profileImage"
              class="rounded-full cursor-pointer h-10 w-10 p-1 hover:shadow-outline navbar-profile-image"
              :src="profileImage"
              alt="User profile image"
              data-cy="usermenu"
            />
            <div
              v-if="!profileImage"
              class="bg-offwhite h-10 w-10 rounded-full flex items-center justify-center hover:text-white hover:bg-primary"
            >
              <span class="font-medium">{{ getInitials() }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex-auto self-center flex justify-end items-center flex-row mobile:hidden pr-6"
        v-if="
          !this.$store.state.auth.status.loggedIn && !this.$route.meta.isProto
        "
      >
        <router-link to="/login" class="mobile:hidden">
          <button
            class="my-3 mx-2 self-end mobile:text-sm btn-primary p-2"
            :class="$route.name === 'Login' ? 'bg-white' : ''"
          >
            <span class="label text-lg py-1">Logi sisse</span>
          </button>
        </router-link>
      </div>
      <div class="navbar-menu-container">
        <notification-tray
          v-if="notificationTrayActive"
          ref="notificationTray"
          :active="notificationTrayActive"
          @closeTray="setNotificationTrayState(false)"
        />
        <user-menu :active="showUserMenu"></user-menu>
        <chat-tray
          v-if="config.features.chat && chatTrayActive"
          :active="chatTrayActive"
          @closeTray="setChatTrayState(false)"
        />
      </div>
    </div>
    <div
      class="verify-email-notification"
      v-if="
        $store.state.auth.status.loggedIn &&
        !$store.state.userData.user.is_email_verified &&
        $store.state.userData.retrieved &&
        this.$route.name !== 'ResendActivation'
      "
    >
      <p>
        Et kasutada lehe funktsioone, palun kinnitage oma meilikonto. Kui te ei
        ole vastavat e-kirja saanud, vajutage
        <router-link
          to="/resend-activation"
          class="cursor-pointer text-highlightblue"
          >siia</router-link
        >.
      </p>
    </div>
  </nav>
</template>

<script>
import jwtdecode from "jwt-decode";
import NotificationTray from "./navbar/NotificationTray";
import UserMenu from "./navbar/UserMenu";
import TimerComponent from "@/components/reusable/TimerComponent";
import { mapGetters } from "vuex";
import config from "../../gewodo.config.json";
import ChatTray from "@/components/navbar/ChatTray";
export default {
  data() {
    return {
      showUserMenu: false,
      navMenuVisible: false,
      notificationTrayActive: false,
      notificationTrayToggleCooldown: false,
      notificationTrayButtonHovered: false,
      hamburgerActive: false,
      timer: null,
      timerInterval: null,
      showCompanySelector: false,
      config: config,
      chatTrayActive: false,
      chatTrayToggleCooldown: false,
      profileImage: null,
    };
  },
  components: {
    ChatTray,
    NotificationTray,
    UserMenu,
    TimerComponent,
  },
  methods: {
    setChatTrayState(state) {
      if (state) {
        this.showCompanySelector = false;
        this.notificationTrayActive = false;
        this.showUserMenu = false;
      }
      if (!this.chatTrayToggleCooldown) this.chatTrayActive = state;
      this.chatTrayToggleCooldown = true;
      setTimeout(() => {
        this.chatTrayToggleCooldown = false;
      }, 50);
    },
    setNotificationTrayState(state) {
      if (state) {
        this.showCompanySelector = false;
        this.showUserMenu = false;
        this.chatTrayActive = false;
      }
      if (!this.notificationTrayToggleCooldown)
        this.notificationTrayActive = state;
      this.notificationTrayToggleCooldown = true;
      setTimeout(() => {
        this.notificationTrayToggleCooldown = false;
      }, 50);
    },
    toggleUserMenu() {
      if (this.notificationTrayActive) this.notificationTrayActive = false;

      this.showUserMenu = !this.showUserMenu;
    },
    goToNotificationTarget(notification) {
      this.$store.dispatch("notifications/readNotification", notification.id);
      this.$router.push(notification.notification_target);
    },
    getInitials() {
      let username = this.$store.state.userData.user.display_username;
      username = username.split(" ");
      username = `${username[0][0]} ${username[1][0]}`;
      return username;
    },
    changeActiveCompany(companyId) {
      this.$store
        .dispatch("companyData/activateCompany", companyId)
        .then(() => {
          this.showCompanySelector = false;
          this.$emit("activeCompanyChanged");
        });
    },
    setCompanyAsDefault(company) {
      this.$store.dispatch("userData/updateDefaultCompany", company.uuid);
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "companyData/activeCompany",
      companies: "companyData/companies",
      userRole: "companyData/userPermission",
      latestNotification: "chat/latestNotification",
    }),
    addTaskDataInput() {
      if (this.$route.name === "ProjectView")
        return { project: this.$store.getters["pages/project/data"] };
      return {};
    },
    profileId() {
      if (this.$store.state.auth.status.loggedIn) {
        return jwtdecode(this.$store.state.auth.user).user_id;
      } else {
        return "";
      }
    },
    logoLink() {
      if (this.$store.state.auth.status.loggedIn) {
        if (this.$store.state.companyData.companies.length === 0)
          return "/company/new";
        return this.config.homepage
          ? this.config.homepage
          : "/invoices/incoming";
      }
      return "/login";
    },
  },
  watch: {
    $route() {
      this.notificationTrayActive = false;
      this.showUserMenu = false;
      this.navMenuVisible = false;
      this.showCompanySelector = false;
    },
  },
};
</script>

<style lang="scss">
.navbar-cont {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    95deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 28%,
    rgba(239, 239, 239, 1) 100%
  );
}
.navbar-button {
  @apply h-10 w-10 bg-offwhite rounded-full flex items-center justify-center hover:bg-primary duration-75;
  &.router-link-active {
    @apply bg-primary;
    img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(150deg)
        brightness(109%) contrast(103%);
    }
  }
}
.navbar-logout {
  @apply bg-white;
}
.navbar-menu-container {
  @apply absolute w-64 flex justify-end;
  right: 2%;
  top: 100%;
  height: auto;
}

.notification-menu-container {
  @apply absolute w-64 flex justify-end;
  right: 2%;
  top: 100%;
}

.mobile-hamburger-menu {
  display: none;
  position: absolute;
}
.company-selector {
  @apply absolute bg-offwhite rounded-b w-full;
  top: 100%;
  left: 0;
  div {
    @apply px-3 p-1;
  }
}
.chat-notification {
  @apply flex flex-col w-64 justify-center items-center bg-white rounded-b;
  &.hide {
    @apply h-0 opacity-0;
  }
  &.show {
    @apply opacity-100;
    height: auto;
  }
  transition: height 0.15s;
  transition: opacity 0.15s;
}

@keyframes noti-slide-in {
  0% {
    height: 0px;
  }
  1% {
    height: 1px;
  }
  100% {
    height: 100px;
  }
}

@media screen and (max-width: 992px) {
  .mobile-hamburger-menu {
    @apply bg-white flex-col px-3 py-4 shadow-lg;
    top: 100%;
    left: 0%;
    height: auto;
    width: 100vw;
    display: flex;
    .nav-item {
      @apply mb-4;
    }
  }
  .navbar-menu-container {
    width: 100vw;
    right: 0%;
  }
}
</style>
