<template>
  <div
    class="dashboard-sidebar-cont"
    :class="screenWidth > 992 ? 'w-36' : 'w-100'"
  >
    <transition name="fade" :duration="{ enter: 300, leave: 20 }">
      <div
        class="dashboard-sidebar"
        v-if="
          expanded &&
          $store.state.companyData.retrieved &&
          userGroup &&
          accountingUserGroup
        "
      >
        <sidebar-link
          target="/calendar"
          icon="/bc21/calendar.svg"
          text="Kalender"
          :permissions="['R0', 'R1', 'R2', 'R3', 'R4', 'R5']"
          :accounting-permissions="['R0', 'R1', 'R2', 'R3', 'R4']"
          v-if="config.features.calendar"
        />
        <div class="dashboard-sidebar-divider mobile:hidden" />
        <sidebar-link
          target="/invoices/incoming"
          icon="/icons/templates/templates.svg"
          text="Ostuarved"
          :permissions="['R0', 'R1', 'R2', 'R4', 'R5']"
          :accounting-permissions="['R0', 'R1', 'R2', 'R3']"
          v-if="config.features.incoming_invoice"
        />
        <sidebar-link
          target="/invoices/outgoing"
          icon="/icons/templates/templates.svg"
          text="Müügiarved"
          :permissions="['R0', 'R1', 'R2']"
          :accounting-permissions="['R3', 'R1', 'R2', 'R0']"
          :submenu="[]"
          v-if="config.features.outgoing_invoice"
        />
        <sidebar-link
          text="E-arved"
          target="/invoices/einvoices"
          :accounting-permissions="['R3', 'R1', 'R2', 'R0']"
          v-if="config.features.incoming_invoice"
        />
        <sidebar-link
          text="Mallid"
          target="/invoices/incoming-templates"
          :accounting-permissions="['R3', 'R1', 'R2', 'R0']"
          v-if="config.features.incoming_invoice"
        />
        <sidebar-link
          target="/transfers"
          icon="/bc21/copy.svg"
          text="Laekumised"
          :permissions="['R0', 'R1', 'R2', 'R3']"
          :accounting-permissions="['R0', 'R1', 'R2']"
          v-if="config.features.transfers"
        />
        <div class="dashboard-sidebar-divider mobile:hidden" />
        <sidebar-link
          target="/projects"
          icon="/bc21/clipboard.svg"
          text="Tellimused"
          :permissions="['R0', 'R1', 'R2', 'R3']"
          :accounting-permissions="['R0', 'R1', 'R2', 'R3', 'R4']"
          v-if="config.features.projects"
        />
        <sidebar-link
          target="/tasks"
          icon="/bc21/clipboard.svg"
          text="Tööd"
          :permissions="['R0', 'R1', 'R2', 'R3']"
          :accounting-permissions="['R0', 'R1', 'R2', 'R3', 'R4']"
          v-if="config.features.projects"
        />
        <sidebar-link
          target="/workers"
          icon="/bc21/töötajad.svg"
          text="Töötajad"
          :permissions="['R0', 'R1', 'R2', 'R3', 'R4', 'R5']"
          :accounting-permissions="['R0', 'R1', 'R2', 'R3', 'R4']"
          :submenu="[
            {
              title: 'Oskused',
              target: '/workers/attributes',
              permissions: ['R0', 'R1', 'R2'],
            },
            {
              title: 'Brigaadid',
              target: '/workers/brigades',
              permissions: ['R0', 'R1', 'R2'],
            },
          ]"
          v-if="config.features.workers"
        />
        <sidebar-link
          target="/resources"
          icon="/bc21/töölaud.svg"
          text="Ressursid"
          :permissions="['R0', 'R1', 'R2']"
          :accounting-permissions="['R0', 'R1', 'R2', 'R3', 'R4']"
          v-if="config.features.resources"
        />
        <sidebar-link
          target="/suppliers"
          icon="/bc21/kliendid.svg"
          text="Hankijad"
          :permissions="['R0', 'R1', 'R2']"
          :accounting-permissions="['R3', 'R1', 'R2', 'R0']"
          :submenu="[
            {
              title: 'Hankijate grupid',
              target: '/suppliers/groups',
              accountingPermissions: ['R0', 'R1'],
            },
          ]"
          v-if="config.features.suppliers"
        />
        <sidebar-link
          target="/costs"
          icon="/icons/worker/money.svg"
          text="Kulud"
          :permissions="['R0', 'R1', 'R2']"
          v-if="config.features.costs"
        />
        <sidebar-link
          target="/equipment"
          icon="/icons/tehnika.svg"
          text="Tehnika"
          :accounting-permissions="['R0', 'R1', 'R2']"
          v-if="config.features.equipment"
        />
        <sidebar-link
          target="/clients"
          icon="/bc21/kliendid.svg"
          text="Kliendid"
          :permissions="['R0', 'R1', 'R2']"
          :accounting-permissions="['R0', 'R1', 'R2', 'R3', 'R4']"
          v-if="config.features.clients"
        />
        <sidebar-link
          target="/files"
          icon="/bc21/dokumendid.svg"
          text="Dokumendid"
          :permissions="['R0', 'R1', 'R2', 'R3']"
          :accounting-permissions="['R0', 'R1', 'R2', 'R3', 'R4']"
          v-if="config.features.files"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import SidebarLink from "@/components/reusable/SidebarLink";
import { mapGetters } from "vuex";
import config from "../../../gewodo.config.json";
import { win } from "leaflet/src/core/Browser";
export default {
  name: "CompanyNavbar",
  components: { SidebarLink },
  props: {
    step: {
      type: Number,
      default: null,
    },
    showOffers: Boolean,
    showWorkers: Boolean,
    showFiles: Boolean,
    expanded: Boolean,
  },
  data() {
    return {
      companySelectorActive: true,
      settingsHovered: false,
      settingsTabHovered: false,
      config: config,
      screenWidth: window.innerWidth,
    };
  },
  methods: {
    win() {
      return win;
    },
    toggleStep(stepToGoTo) {
      if (this.$route.name === "CompanyView") {
        this.$emit("stepChange", stepToGoTo);
        if (this.step === 3) {
          this.$emit("checkDocumentPageState");
        }
        if (this.step === 5) {
          this.$emit("checkWorkerPageState");
        }
        if (this.step === 8) {
          this.$emit("checkClientPageState");
        }
      } else {
        this.$router.push("/calendar?v=" + stepToGoTo);
      }
    },
    changeActiveCompany(companyId) {
      this.$store.dispatch("companyData/activateCompany", companyId);
      this.$emit("activeCompanyChanged");
    },
    onResize() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    ...mapGetters({
      userGroup: "companyData/userPermission",
      accountingUserGroup: "companyData/userAccountingPermission",
    }),
  },
};
</script>
