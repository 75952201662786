<template>
  <div class="modal">
    <div
      class="modal-body modal-short mobile:modal-full"
      v-on-clickaway:mousedown="closeModal"
    >
      <div class="modal-header p-3">
        <h3>{{ planningCost ? "Planeeri lisatöö" : "Lisa lisatöö kulu" }}</h3>
      </div>
      <div class="modal-content flex-col gap-y-4" v-if="templatesLoaded">
        <div class="flex w-full mobile:flex-col" v-if="isManager">
          <div class="flex w-1/2 mobile:w-full">
            <div
              class="flex flex-col w-full"
              v-if="!planningCost && !selectedWorker"
            >
              <gwd-formlabel title="Vali töötaja" class="mb-2"></gwd-formlabel>
              <div
                v-for="worker in workers"
                :key="worker.id"
                @click="toggleWorker(worker)"
                class="flex mb-1 items-center cursor-pointer"
              >
                <span>{{ worker.worker_name }}</span>
              </div>
              <div v-if="workers.length === 0" class="mb-2">
                <span class="p-2">Tööl puutuvad töötajad</span>
              </div>
            </div>
            <div v-if="!planningCost && selectedWorker" class="flex flex-col">
              <h4>Töötaja</h4>
              <div class="flex justify-between">
                <span>{{ selectedWorker.worker_name }}</span>
                <button
                  v-if="!presetWorker && !['R4', 'R5'].includes(userPermission)"
                  @click="
                    () => {
                      selectedWorker = null;
                    }
                  "
                  class="btn-primary ml-2"
                  tabindex="1"
                >
                  <span class="label">Muuda</span>
                </button>
              </div>
            </div>
          </div>
          <div class="flex w-1/2 mobile:w-full">
            <div class="flex flex-col w-full" v-if="presetWorker || project">
              <h4>Tellimus</h4>
              <div
                class="flex items-center justify-between"
                v-if="selectedProject"
              >
                <span>{{ selectedProject.title }}</span>
                <button
                  v-if="!project"
                  class="btn-primary"
                  @click="selectedProject = null"
                >
                  <span class="label">Muuda</span>
                </button>
              </div>
              <button
                @click="projectSelectorActive = true"
                class="btn-primary"
                v-if="!selectedProject"
              >
                <span class="label">Vali tellimus</span>
              </button>
              <div class="flex flex-col my-2" v-if="selectedProject">
                <h4>Töö</h4>
                <div
                  class="flex flex-row items-center justify-between"
                  v-if="selectedTask"
                >
                  <span class="label">{{ selectedTask.title }}</span>
                  <button class="btn-primary" @click="selectedTask = null">
                    <span class="label">Muuda</span>
                  </button>
                </div>
                <div
                  class="flex flex-col overflow-y-scroll max-h-64 pr-2 py-1"
                  v-else
                >
                  <div
                    v-for="projectTask in projectTasks"
                    :key="projectTask.id"
                    class="px-2 py-1 shadow cursor-pointer w-full flex justify-between items-center mobile:w-full duration-100 bg-offwhite rounded pr-1"
                    @click="handleTaskSelection(projectTask)"
                  >
                    <h4 class="truncate">{{ projectTask.title }}</h4>
                    <span v-html="parseTaskStatus(projectTask.status)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-y-2" v-if="isManager">
          <div class="flex gap-x-2 mobile:flex-col mobile:gap-y-2">
            <div class="flex flex-col gap-y-1 flex-1">
              <gwd-formlabel title="Kirjeldus" :required="true" />
              <input
                type="text"
                class="add-offer-input"
                v-model="newCost.name"
              />
            </div>
            <div
              class="flex flex-col gap-y-1 flex-1"
              v-if="!templateSelected && extraCostTemplates.length > 0"
            >
              <h4>Mall</h4>
              <select-search
                :dataset="extraCostTemplates"
                :label="(item) => item.name"
                class="flex-grow"
                :show-on-focus="true"
                :filter-func="filterTemplates"
                ref="templateSelect"
                placeholder="Otsi malli"
                @input="setTemplateAsCost"
                v-on-clickaway="
                  () => {
                    if (this.$refs.templateSelect) {
                      this.$refs.templateSelect.hasFocus = false;
                    }
                  }
                "
              />
            </div>
          </div>

          <div class="flex gap-x-2 mobile:gap-y-4 mobile:flex-col">
            <div class="flex flex-col gap-y-1 flex-1">
              <div class="flex gap-x-3 items-center triple-field">
                <gwd-formlabel title="Ühiku kulu" />
                <gwd-formlabel title="Koefitsent" />
                <gwd-formlabel title="Kogukulu" />
              </div>
              <div class="flex gap-x-3 items-center triple-field">
                <decimal-input
                  class="add-offer-input"
                  min="0"
                  v-model.number="newCost.cost"
                />
                <decimal-input
                  class="add-offer-input"
                  min="0"
                  v-model.number="newCost.cost_coefficient"
                />
                <span class="flex">
                  {{ newCost.cost * newCost.cost_coefficient * newCost.amount }}
                </span>
              </div>
            </div>
          </div>
          <div
            class="flex gap-x-2 mobile:gap-y-4 mobile:flex-col"
            v-if="isManager"
          >
            <div class="flex flex-col gap-y-1 flex-1">
              <div class="flex gap-x-3 items-center triple-field">
                <gwd-formlabel title="Müügihind maksuta" />
                <gwd-formlabel title="Maks" />
                <gwd-formlabel title="Müügihind maksudega" />
              </div>
              <div class="flex gap-x-3 items-center triple-field">
                <decimal-input
                  class="add-offer-input"
                  min="0"
                  v-model.number="newCost.price"
                />
                <select class="max-w-full w-full" v-model="newCost.vatType">
                  <option :value="null">Puudub</option>
                  <option
                    v-for="taxType in taxTypes.filter(
                      (x) => x.outgoing_tax_code
                    )"
                    :key="taxType.id"
                    :value="taxType"
                  >
                    {{
                      `${taxType.description} (${
                        taxType.accounting_code ? taxType.accounting_code : "-"
                      })`
                    }}
                  </option>
                </select>

                <span class="flex">
                  {{ priceWithVat(newCost) }}
                </span>
              </div>
            </div>
          </div>
          <div class="flex gap-x-2">
            <div class="flex flex-col gap-y-1 flex-1">
              <gwd-formlabel title="Kogus" />
              <decimal-input
                class="add-offer-input"
                v-model.number="newCost.amount"
              />
            </div>
            <div class="flex flex-col gap-y-1 flex-1">
              <gwd-formlabel title="Ühik" />
              <select class="add-offer-input" v-model="newCost.unit">
                <option v-for="unit in units" :value="unit" :key="unit">
                  {{ unit }}
                </option>
              </select>
            </div>
          </div>
          <div class="flex gap-x-2">
            <div class="flex flex-col gap-y-1 flex-1">
              <gwd-formlabel title="Lisainfo" />
              <input type="text" v-model="newCost.info" />
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-y-2" v-else>
          <div class="flex gap-x-2 mobile:flex-col mobile:gap-y-2">
            <div class="flex flex-col gap-y-1 flex-1">
              <gwd-formlabel
                title="Kirjeldus"
                :required="true"
                :valid="newCost.name && newCost.name.length > 0"
                :error="!newCost.name || newCost.name.length === 0"
              />
              <input
                type="text"
                class="add-offer-input"
                v-model="newCost.name"
              />
            </div>
            <div
              class="flex flex-col gap-y-1 flex-1"
              v-if="
                !templateSelected &&
                extraCostTemplates &&
                extraCostTemplates.length > 0
              "
            >
              <h4>Mall</h4>
              <select-search
                :dataset="extraCostTemplates"
                :label="(item) => item.name"
                class="flex-grow"
                :tabindex="1"
                :show-on-focus="true"
                :filter-func="filterTemplates"
                :ref="'templateSelect'"
                placeholder="Otsi malli"
                @input="setTemplateAsCost"
              />
            </div>
          </div>
          <div class="flex gap-x-2 mobile:gap-y-4 mobile:flex-col">
            <div class="flex flex-col gap-y-1 flex-1 gap-x-2">
              <gwd-formlabel title="Ühiku hind" />
              <decimal-input
                class="add-offer-input"
                min="0"
                v-model.number="newCost.cost"
              />
            </div>
            <div class="flex flex-col gap-y-1 flex-1">
              <gwd-formlabel title="Kogus" />
              <decimal-input
                class="add-offer-input"
                v-model.number="newCost.amount"
              />
            </div>
            <div class="flex flex-col gap-y-1 flex-1">
              <gwd-formlabel title="Ühik" />
              <select class="add-offer-input" v-model="newCost.unit">
                <option v-for="unit in units" :value="unit" :key="unit">
                  {{ unit }}
                </option>
              </select>
            </div>
          </div>
          <div class="flex gap-x-2">
            <div class="flex flex-col gap-y-1 flex-1">
              <gwd-formlabel title="Lisainfo" />
              <input type="text" v-model="newCost.info" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button class="btn-primary mr-3" @click="saveExtraCost">
          Salvesta
        </button>
        <button class="btn-danger" @click="closeModal">Sulge</button>
      </div>
      <project-selector
        v-if="projectSelectorActive"
        :convert="false"
        @projectAdded="handleProjectSelection"
        @closeModal="projectSelectorActive = false"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectSearch from "@/components/reusable/SelectSearch.vue";
import ProjectSelector from "@/components/tasks/ProjectSelector.vue";
import { StatusParser } from "@/assets/mixins/GewodoMixins";
import DecimalInput from "../../reusable/DecimalInput.vue";
import { EventBus } from "../../../assets/mixins/GewodoMixins";
import { priceWithVat } from "@/assets/utils/commonMath";
export default {
  name: "AddExtraCostModal",
  components: { ProjectSelector, SelectSearch, DecimalInput },
  props: {
    task: {
      type: Number,
      default: null,
    },
    taskWorkers: {
      type: Array,
      default: () => {
        return [];
      },
    },
    planningCost: {
      type: Boolean,
      default: false,
    },
    presetWorker: {
      type: Object,
      default: null,
    },
    project: {
      type: Object,
      default: null,
    },
  },
  mixins: [StatusParser],
  data() {
    return {
      extraCostTemplates: [],
      workers: [],
      templatesLoaded: false,
      newCost: {
        price: 0.0,
        cost: 0.0,
        name: "",
        amount: 0,
        unit: "tk",
        info: "",
        cost_template_id: null,
        cost_coefficient: 1.0,
        vat: null,
        vatType: null,
      },
      selectedWorker: null,
      searchQuery: "",
      projectSelectorActive: false,
      selectedProject: null,
      selectedTask: null,
      projectTasks: [],
      templateSelected: false,
    };
  },
  methods: {
    priceWithVat,
    closeModal() {
      this.$emit("closeModal");
    },
    handleTaskSelection(e) {
      this.selectedTask = e;
    },
    loadProjectTasks(project) {
      this.apiRequest(
        `calendar/${this.companyId}/${project.uuid}/tasks/?period=all`,
        "get",
        true
      ).then((res) => {
        this.projectTasks = res.data;
      });
    },
    handleProjectSelection(e) {
      this.selectedProject = e;
      this.projectSelectorActive = false;
      this.loadProjectTasks(e);
    },
    toggleWorker(worker) {
      if (this.selectedWorker === worker) {
        this.selectedWorker = null;
      } else {
        this.selectedWorker = worker;
      }
    },
    filterTemplates(template, query) {
      return template.name.toLowerCase().includes(query.toLowerCase());
    },
    loadExtraCostTemplates() {
      this.apiRequest(
        `template/${this.companyId}/extras/templates/`,
        "get",
        true
      ).then((res) => {
        this.extraCostTemplates = res.data;
        this.templatesLoaded = true;
      });
    },
    setTemplateAsCost(e) {
      this.newCost.name = e.name;
      this.newCost.cost = e.cost;
      this.newCost.price = e.price ?? null;
      this.newCost.cost_coefficient = e.cost_coefficient ?? null;
      this.newCost.vat = e.vat ?? null;
      if (e.vat) {
        this.newCost.vatType = this.taxTypes.find((x) => x.id === e.vat);
      }
      this.newCost.amount = e.amount;
      this.newCost.unit = e.unit;
      this.newCost.cost_template = e.id;
      this.templateSelected = true;
    },
    async saveExtraCost() {
      if (!this.newCost.name) return;
      const data = {
        task: this.task
          ? this.task
          : this.selectedTask
          ? this.selectedTask.id
          : null,
        project: this.selectedProject ? this.selectedProject.uuid : null,
        ...this.newCost,
        worker: this.selectedWorker ? this.selectedWorker.id : null,
      };
      if (this.newCost.vatType) data.vat = this.newCost.vatType.id;
      let url = this.planningCost
        ? `template/${this.companyId}/extras/create/`
        : `costs/${this.companyId}/worker/${
            this.selectedWorker
              ? this.selectedWorker.id
              : this.workerInstance.id
          }/extras/add/`;

      const dataCheck = await this.$store
        .dispatch("modals/updateCheckModal/compareData", {
          name: this.planningCost ? "extraCostPlanning" : "extraCost",
          sendUrl: url,
          sendMethods: "post",
          isAuthed: true,
          data: data,
          postSaveEventBus: "costSaved",
          saveCancelActions: [
            {
              type: "commit",
              action: "modals/taskModal/closeAddExtraCostModal",
            },
            {
              type: "bus",
              action: "costSaved",
            },
          ],
        })
        .then((res) => {
          return res;
        });
      if (!dataCheck) return;

      this.apiRequest(url, "post", true, data)
        .then((res) => {
          if (res.status === 201) {
            this.$emit("costSaved");
            EventBus.$emit("costSaved");
            this.closeModal();
          }
        })
        .catch((err) => {
          switch (err.response.status) {
            case 406:
              this.$store.dispatch("messageHandler/throwMessage", {
                type: "error",
                error: err,
                text: "Ei saa lõpetatud tööle lisakulusid määrata! Võta tellimuse haldajaga ühendust.",
              });
              break;
            default:
              this.$store.dispatch("messageHandler/throwMessage", {
                type: "error",
                error: err,
                text: "Midagi läks valesti",
              });
          }
        });
    },
    loadProjectWorkers(projectId) {
      this.apiRequest(
        `notes/${this.companyId}/${projectId}/workers/`,
        "get",
        true
      ).then((res) => {
        this.workers = res.data;
      });
    },
  },
  async mounted() {
    if (this.isManager && !this.taxTypes)
      await this.$store.dispatch("companyVariables/retrieveTaxTypes");
    if (!this.planningCost && ["R4", "R5"].includes(this.userPermission)) {
      this.selectedWorker = this.workerInstance;
    }
    if (this.presetWorker) {
      this.selectedWorker = this.presetWorker;
    }
    if (this.project) {
      this.handleProjectSelection(this.project);
      this.loadProjectWorkers(this.project.uuid);
    }
    if (this.taskWorkers) {
      this.workers = this.taskWorkers;
    }
    this.loadExtraCostTemplates();
    this.newCost.cost_coefficient = parseFloat(this.defaultCoefficient ?? "1");
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      userPermission: "companyData/userPermission",
      workerInstance: "companyData/workerInstance",
      units: "companyVariables/units",
      isManager: "companyData/isManager",
      defaultCoefficient: "companyData/defaultCoefficient",
      taxTypes: "companyVariables/taxTypes",
    }),
  },
};
</script>
<style lang="scss" scoped>
.triple-field {
  @apply grid;
  grid-template-columns: 0.35fr 0.35fr 0.3fr;
}
</style>
