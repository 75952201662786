<template>
  <div class="flex flex-col gap-y-4">
    <div v-if="$v.$error && $v.$invalid" class="error-box">
      <div class="flex flex-col w-10/12 justify-center">
        <span class="flex" v-if="!$v.emailInput.email"
          >Meiliaadress on ebakorrektne</span
        >
        <span
          class="flex"
          v-if="
            phoneNumberInput.length > 0 &&
            (!$v.phoneNumberInput.minLength || !$v.phoneNumberInput.maxLength)
          "
          >Telefoninumber on ebakorrektne (7-8 numbrit)</span
        >
      </div>
    </div>
    <div class="flex w-full flex-col gwd-form">
      <div class="gwd-form-hor-group new-contact-form-row">
        <h4>Nimi</h4>
        <input class="add-project-input" v-model="contact.name" />
      </div>
      <div class="gwd-form-hor-group new-contact-form-row">
        <h4>Meiliaadress</h4>
        <input class="add-project-input w-full" v-model="emailInput" />
      </div>
      <div class="gwd-form-hor-group new-contact-form-row">
        <h4>Roll</h4>
        <input class="add-project-input" v-model="contact.role" />
      </div>
      <div class="gwd-form-group">
        <h4>Lisainfo</h4>
        <gwd-textarea class="add-project-input w-full" v-model="contact.info" />
      </div>
      <div class="gwd-form-group">
        <h4>Telefoninumber</h4>
        <div class="flex">
          <input
            class="add-project-input w-full"
            placeholder="Telefoninumber"
            v-model="phoneNumberInput"
            type="tel"
            pattern="[0-9]*"
            :class="
              phoneNumberInput.length > 0 &&
              $v.$error &&
              $v.phoneNumberInput.$invalid
                ? 'shadow-dangeroutline'
                : ''
            "
          />
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-start gap-x-2">
      <button @click="save" class="btn-primary">
        <span class="icon typcn typcn-tick"></span>
        <span class="label">Salvesta</span>
      </button>
      <button @click="close()" class="new-button-danger">
        <span class="icon typcn typcn-times"></span>
        <span class="label">Tühista</span>
      </button>
    </div>
  </div>
</template>

<script>
import {
  email,
  maxLength,
  minLength,
  required,
} from "vuelidate/lib/validators";

export default {
  name: "NewClientContactForm",
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneNumberInput: "",
      countryCode: "+372",
      emailInput: "",
      contact: {
        name: "",
        email: "",
        telephone_number: "",
        role: "",
        info: "",
      },
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      this.$v.$touch();
      if (
        !this.$v.$invalid &&
        !(
          this.contact.name === "" &&
          this.contact.email === "" &&
          this.contact.role === ""
        )
      ) {
        if (this.phoneNumberInput.length > 0) {
          this.contact.telephone_number =
            this.countryCode + this.phoneNumberInput;
        }
        this.$emit("save", this.contact);
        this.close();
      }
    },
  },
  watch: {
    emailInput() {
      this.contact.email = this.emailLowercased;
    },
    phoneNumberInput() {
      const test = new RegExp(/[a-zA-Z\s]/);
      if (this.phoneNumberInput !== null) {
        this.phoneNumberInput = this.phoneNumberInput.replace(test, "");
      }
    },
  },
  computed: {
    emailLowercased() {
      return this.emailInput.toLowerCase();
    },
  },
  validations() {
    if (this.phoneNumberInput !== null && this.phoneNumberInput.length > 0) {
      return {
        phoneNumberInput: {
          required,
          minLength: minLength(7),
          maxLength: maxLength(8),
        },
        emailInput: {
          email,
        },
      };
    } else {
      return {
        emailInput: {
          email,
        },
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.new-contact-form-row {
  @apply grid;
  grid-template-columns: 0.3fr 0.7fr;
}
</style>
