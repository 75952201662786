import { addTaskModal } from "./modals/addTaskModal.module";
import { taskModal } from "./modals/taskModal.module";
import { addInvoiceModal } from "./modals/addInvoiceModal.module";
import { invoiceModal } from "./modals/invoiceModal.module";
import { companyInvoiceModal } from "./modals/companyInvoiceModal.module";
import { supplierCostModal } from "./modals/supplierCostModal";
import { offerModal } from "./modals/offerModal.module";
import { addOfferModal } from "./modals/addOfferModal.module";
import { addResourceModal } from "./modals/addResourceModal.module";
import { equipmentUnavailabilityModal } from "./modals/equipmentUnavailabilityModal.module";
import { fileModal } from "./modals/fileModal.module";
import { taskPlanningModal } from "./modals/taskPlanningModal.module";
import { addEquipmentUnavailabilityModal } from "./modals/addEquipmentUnavailabilityModal.module";
import { addClientModal } from "./modals/addClientModal.module";
import { updateCheckModal } from "./modals/updateCheckModal.module";

export const modals = {
  namespaced: true,
  modules: {
    taskPlanningModal,
    addTaskModal,
    taskModal,
    addInvoiceModal,
    invoiceModal,
    companyInvoiceModal,
    supplierCostModal,
    offerModal,
    addOfferModal,
    addResourceModal,
    equipmentUnavailabilityModal,
    addEquipmentUnavailabilityModal,
    fileModal,
    addClientModal,
    updateCheckModal,
  },
};
