import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";

import CompanyRoutes from "./modules/company";
import CoreRoutes from "./modules/core";
import ClientRoutes from "./modules/client";
import AccountRoutes from "./modules/account";
import config from "../../gewodo.config.json";
Vue.use(VueRouter);

const routes = [].concat(
  CompanyRoutes,
  CoreRoutes,
  ClientRoutes,
  AccountRoutes
);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const promises = [];
  const targetRoute = routes.find((item) => item.name === to.name);
  const accessToken = localStorage.getItem("access");
  const refreshToken = localStorage.getItem("refresh");
  const isLoggedIn = store.state.auth.status.loggedIn;
  const isPublic = targetRoute.meta?.public;

  if (!targetRoute || targetRoute.meta.disabled) next(config.homepage);
  await store.dispatch("userData/initCookieState");

  if (isLoggedIn) {
    if (!store.state.userData.retrieved)
      promises.push(await store.dispatch("userData/retrieveProfileData"));

    if (!store.state.companyData.retrieved)
      promises.push(store.dispatch("companyData/retrieveCompanyData"));

    Promise.all(promises).then(() => {
      if (
        store.state.userData.retrieved &&
        store.state.companyData.retrieved &&
        !store.state.companyData.isClient
      )
        promises.push(store.dispatch("timetracking/retrieveTimers"));

      if (
        store.state.userData.user.is_email_verified &&
        config.features.chat &&
        !store.state.notifications.websocketTried
      ) {
        promises.push(store.dispatch("notifications/retrieveNotifications"));
        promises.push(store.dispatch("chat/retrieveChatNotifications"));
        promises.push(store.dispatch("chat/retrieveParties"));
      }
    });

    Promise.all(promises)
      .then(() => {
        const hasPermissions =
          !to.meta.permissions ||
          (to.meta.permissions &&
            to.meta.permissions.includes(
              store.getters["companyData/userPermission"]
            ));
        const hasAccountingPermissions =
          !to.meta.accountingPermissions ||
          (to.meta.accountingPermissions &&
            to.meta.accountingPermissions.includes(
              store.getters["companyData/userAccountingPermission"]
            ));
        if (
          to.path === config.homepage &&
          (!hasPermissions || !hasAccountingPermissions)
        )
          return next("/calendar");
        if (
          to.path === "/" ||
          !to ||
          !hasPermissions ||
          !hasAccountingPermissions
        ) {
          return next(config.homepage);
        }

        return next();
      })
      .catch((err) => console.error(err));
  } else {
    if (isPublic) next();
    else if (accessToken && refreshToken) {
      store
        .dispatch("auth/relogin")
        .then(
          () => next(config.homepage),
          (err) => {
            console.error(err);
            store.dispatch("auth/logout");
            next("/");
          }
        )
        .catch((err) => {
          console.error(err);
          store.dispatch("auth/logout");
          next("/");
        });
    } else {
      store.dispatch("auth/logout");
      next("/");
    }
  }
});

export default router;
