var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col gap-y-2"},[_c('div',{staticClass:"flex gap-x-2"},[_c('div',{staticClass:"flex w-4 self-center cursor-pointer",on:{"click":function($event){return _vm.openTaskLocation(_vm.taskData.location)}}},[_c('span',{staticClass:"w-4 h-4 bg-primary rounded-full"})]),_c('div',{staticClass:"flex flex-col w-full"},[(_vm.isAdmin)?_c('planning-location-selector',{staticClass:"w-full mobile:mb-2",model:{value:(_vm.taskData.location),callback:function ($$v) {_vm.$set(_vm.taskData, "location", $$v)},expression:"taskData.location"}},[_vm._v(" Lähtekoht ")]):_c('div',{staticClass:"flex flex-col gap-x-2 cursor-pointer",on:{"click":function($event){return _vm.openTaskLocation(_vm.taskData.location)}}},[_c('h4',[_vm._v("Lähtekoht")]),_c('span',[_vm._v(" "+_vm._s(_vm.startLocation)+" ")]),(_vm.taskData.location && _vm.taskData.location.info)?_c('span',[_vm._v(" "+_vm._s(_vm.taskData.location.info)+" ")]):_vm._e()])],1)]),_vm._l((_vm.taskData.extra_locations),function(extraLocation){return _c('div',{key:extraLocation.id,staticClass:"flex gap-x-2 gap-y-1"},[_vm._m(0,true),_c('div',{staticClass:"flex flex-col w-full"},[(_vm.isAdmin)?_c('planning-location-selector',{staticClass:"w-full mobile:mb-2",attrs:{"extra-location":true},on:{"delete":function($event){return _vm.$store.dispatch(
            'modals/taskModal/deleteExtraLocation',
            extraLocation
          )}},model:{value:(extraLocation.location),callback:function ($$v) {_vm.$set(extraLocation, "location", $$v)},expression:"extraLocation.location"}},[_vm._v(" Vahesihtkoht ")]):_c('div',{staticClass:"flex flex-col gap-x-2 cursor-pointer",on:{"click":function($event){return _vm.openTaskLocation(extraLocation.location)}}},[_c('h4',[_vm._v("Vahesihtkoht")]),_c('span',[_vm._v(" "+_vm._s(extraLocation.location ? extraLocation.location.long_address : "-")+" ")]),(extraLocation.location && extraLocation.location.info)?_c('span',[_vm._v(" "+_vm._s(extraLocation.location.info)+" ")]):_vm._e()])],1)])}),(_vm.extraLocations.length > 0)?_c('div',{staticClass:"flex flex-col gap-y-1"},_vm._l((_vm.extraLocations),function(location,index){return _c('div',{key:index,staticClass:"flex w-full gap-x-2"},[_vm._m(1,true),_c('div',{staticClass:"flex flex-col w-full"},[(!location)?_c('planning-location-selector',{ref:"extraLocations",refInFor:true,staticClass:"w-full",attrs:{"emit-standardized-object":true,"extra-location":true},on:{"input":function (e) { return _vm.$store.dispatch("modals/taskModal/updateExtraLocation", {
                index: index,
                newLocation: e,
              }); },"delete":function($event){return _vm.$store.dispatch("modals/taskModal/deleteNotSavedExtraLocation", {
              index: index,
            })}}},[_vm._v(" Vahesihtkoht ")]):_c('div',{staticClass:"flex bg-danger"},[_vm._v(" "+_vm._s(location.full_address)+" ")])],1)])}),0):_vm._e(),(_vm.taskData.location && _vm.taskData.end_location && _vm.isManager)?_c('div',{staticClass:"flex gap-x-2 cursor-pointer rounded-l-full rounded-r hover:bg-primary hover:bg-opacity-10 duration-100",on:{"click":function($event){return _vm.$store.dispatch('modals/taskModal/addExtraLocation')}}},[_vm._m(2),_vm._m(3)]):_vm._e(),_c('div',{staticClass:"flex gap-x-2"},[_c('div',{staticClass:"flex w-4 relative self-center cursor-pointer",on:{"click":function($event){return _vm.openTaskLocation(_vm.taskData.end_location)}}},[_c('span',{staticClass:"w-4 h-4 bg-primary rounded-full"})]),_c('div',{staticClass:"flex flex-col flex-1"},[(_vm.isAdmin)?_c('planning-location-selector',{staticClass:"w-full mobile:mb-2",model:{value:(_vm.taskData.end_location),callback:function ($$v) {_vm.$set(_vm.taskData, "end_location", $$v)},expression:"taskData.end_location"}},[_vm._v(" Sihtkoht ")]):_c('div',{staticClass:"flex flex-col gap-x-2 cursor-pointer",on:{"click":function($event){return _vm.openTaskLocation(_vm.taskData.end_location)}}},[_c('h4',[_vm._v("Sihtkoht")]),_c('span',[_vm._v(" "+_vm._s(_vm.endLocation)+" ")]),(_vm.taskData.end_location && _vm.taskData.end_location.info)?_c('span',[_vm._v(" "+_vm._s(_vm.taskData.end_location.info)+" ")]):_vm._e()])],1)])],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex w-4 relative self-center"},[_c('span',{staticClass:"w-4 h-4 bg-primary rounded-full"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex w-4 self-center"},[_c('span',{staticClass:"w-4 h-4 bg-primary rounded-full"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-4 self-center"},[_c('button',{staticClass:"bg-primary h-4 w-4 rounded-full leading-none flex justify-center items-center text-white"},[_c('span',{staticClass:"typcn typcn-plus leading-none"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex font-medium"},[_c('span',[_vm._v("Lisa vahesihtkoht")])])}]

export { render, staticRenderFns }