<template>
  <div class="flex flex-col gwd-form">
    <div class="gwd-form-hor-group gap-x-2">
      <h4>{{ client.name }}</h4>
      <span>{{ client.is_company ? "Äriklient" : "Eraklient" }}</span>
      <button class="btn-primary" @click="changeClient">Muuda</button>
    </div>
    <div class="gwd-form-hor-group gap-x-2">
      <div class="flex">
        <div
          class="flex rounded-full h-7 w-7 border-primary border-2 items-center justify-center"
        >
          <img
            src="/bc21/placeholder.svg"
            class="filter-to-primary h-4 w-4"
            alt="Location logo"
          />
        </div>
      </div>
      <div class="flex-grwo">
        <span> {{ client.location ? client.location.long_address : "-" }}</span>
      </div>
    </div>
    <div class="gwd-form-group" v-if="client.contacts.length > 0">
      <h4>Kontaktid</h4>
      <client-contact-card
        v-for="contact in client.contacts"
        :key="contact.id"
        :contact="contact"
        :hide-buttons="true"
      />
    </div>
    <div class="gwd-form-hor-group gap-x-2">
      <div class="flex items-center w-1/5">
        <h4>Registrikood</h4>
      </div>
      <span> {{ client.register_code ? client.register_code : "-" }}</span>
    </div>
    <div class="gwd-form-hor-group gap-x-2">
      <div class="flex items-center w-1/5">
        <h4>KMRK kood</h4>
      </div>
      <span>{{ client.tax_code ? client.tax_code : "-" }}</span>
    </div>
    <div class="gwd-form-hor-group gap-x-2">
      <div class="flex items-center w-1/5">
        <h4>Info arvele</h4>
      </div>
      <span>{{
        client.invoice_information_text ? client.invoice_information_text : "-"
      }}</span>
    </div>
    <div class="gwd-form-hor-group gap-x-2">
      <div class="flex items-center w-1/5">
        <h4>Hoiatus</h4>
      </div>
      <span class="text-danger">{{
        client.warning ? client.warning : "-"
      }}</span>
    </div>
  </div>
</template>

<script>
import ClientContactCard from "@/components/company/clients/ClientContactCard.vue";

export default {
  components: { ClientContactCard },
  methods: {
    changeClient() {
      this.$emit("changeClient");
    },
  },
  props: {
    client: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>
