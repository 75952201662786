`
<template>
  <div class="modal">
    <div class="modal-body modal-vwide" v-on-clickaway:mousedown="closeModal">
      <div class="modal-header p-3">
        <h3>Erinevused andmebaasi ning kohalike andmete vahel</h3>
      </div>
      <div v-if="type === 2" class="modal-content flex-col text-xs">
        <div
          class="data-container"
          v-if="
            itemsInDatabaseAndNotInLocal &&
            itemsInDatabaseAndNotInLocal.length > 0
          "
        >
          <div class="data-container-header">
            <gwd-formlabel title="Andmebaasis andmed mis pole kohalikus" />
          </div>
          <div class="data-container-content overflow-x-scroll gap-y-2">
            <table style="table-layout: fixed">
              <tr
                v-for="(row, index) in itemsInDatabaseAndNotInLocal"
                :key="`X${index}`"
              >
                <td
                  v-for="(value, key) in row"
                  :key="`${index}X${key}`"
                  class="w-full h-full"
                  style="width: 1px; white-space: nowrap"
                >
                  <span class="font-semibold"> {{ key }}</span>
                  <div v-if="value && typeof value === 'object'">
                    <div
                      v-for="(value2, key2) in value"
                      :key="`${index}X${key}X${key2}`"
                      class="flex flex-row items-center gap-x-2"
                    >
                      <span> {{ key2 }}</span>
                      {{ value2 }}
                    </div>
                  </div>
                  <div v-else>
                    {{ value ? value : "-" }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div
          class="data-container"
          v-if="
            itemsInLocalAndNotInDatabase &&
            itemsInLocalAndNotInDatabase.length > 0
          "
        >
          <div class="data-container-header">
            <gwd-formlabel
              title="Kohalikud andmed mis pole andmebaasis (Midagi on vahepeal kustunud või muutunud)"
            />
          </div>
          <div class="data-container-content overflow-x-scroll gap-y-2">
            <table style="table-layout: fixed">
              <tr
                v-for="(row, index) in itemsInLocalAndNotInDatabase"
                :key="`Y${index}`"
              >
                <td
                  v-for="(value, key) in row"
                  :key="`${index}Y${key}`"
                  class="w-full h-full"
                  style="width: 1px; white-space: nowrap"
                >
                  <span class="font-semibold"> {{ key }}</span>
                  <div v-if="value && typeof value === 'object'">
                    <div
                      v-for="(value2, key2) in value"
                      :key="`${index}Y${key}Y${key2}`"
                      class="flex flex-row items-center gap-x-2"
                    >
                      <span> {{ key2 }} </span>
                      <span> {{ value2 }} </span>
                    </div>
                  </div>
                  <div v-else>
                    {{ value ? value : "-" }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="data-container">
          <div class="data-container-header">
            <gwd-formlabel title="Lisatavad andmed" />
          </div>
          <table style="table-layout: fixed">
            <tr key="Z">
              <td
                v-for="(value, key) in postData.data"
                :key="`Z${key}`"
                class="w-full h-full"
                style="width: 1px; white-space: nowrap"
              >
                <span class="font-semibold"> {{ key }}</span>
                <div v-if="value && typeof value === 'object'">
                  <div
                    v-for="(value2, key2) in value"
                    :key="`Z${key}Z${key2}`"
                    class="flex flex-row items-center gap-x-2"
                  >
                    <span> {{ key2 }} </span>
                    <span> {{ value2 }} </span>
                  </div>
                </div>
                <div v-else>
                  {{ value ? value : "-" }}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div v-if="type === 1" class="modal-content flex-col text-xs">
        <div class="data-container">
          <div class="data-container-header">
            <h3>Erinevused andmetes</h3>
          </div>
          <div class="data-container-content">
            <div
              v-for="key in Object.keys(this.valuesDiffInLocalAndDataBase)"
              :key="key"
              class="flex items-center gap-x-2"
            >
              <div class="w-1/4">
                <span>Välja nimetus: </span>
                <span class="font-bold">{{ key }}</span>
              </div>
              <span class="mx-3 w-1/4">
                Vana väärtus:
                {{ valuesDiffInLocalAndDataBase[key].prevValues }}</span
              >
              <span>
                Uus väärtus:
                {{ valuesDiffInLocalAndDataBase[key].newValues }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar gap-x-3">
        <button class="btn-danger" @click="closeModal">Tühista lisamine</button>
        <button class="btn-primary" @click="saveAnyways">
          <span v-if="type === 1" class="label"> Salvesta üle </span>
          <span v-if="type === 2" class="label"> Lisa ikka </span>
          <span v-if="type === 3" class="label"> Uh? </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "@/assets/mixins/EventBus";
import { isEqual, omit } from "lodash";

export default {
  name: "UpdateCheckModal",
  data() {
    return {
      type: null,
      itemsInDatabaseAndNotInLocal: [],
      itemsInLocalAndNotInDatabase: [],
      valuesDiffInLocalAndDataBase: [],
      startingData: null,
      databaseData: null,
    };
  },
  methods: {
    closeModal() {
      if (this.postData.saveCancelActions) {
        for (let action of this.postData.saveCancelActions) {
          switch (action.type) {
            case "bus": {
              EventBus.$emit(action.action);
              break;
            }
            case "commit": {
              this.$store.commit(action.action);
              break;
            }
            case "dispatch": {
              this.$store.dispatch(action.action);
              break;
            }
            default:
              break;
          }
        }
      }
      this.$store.dispatch("modals/updateCheckModal/closeModal");
    },
    saveAnyways() {
      this.apiRequest(
        this.postData.sendUrl,
        this.postData.sendMethods,
        this.postData.isAuthed,
        this.postData.data
      )
        .then((res) => {
          if ([200, 201].includes(res.status)) {
            if (this.postData.postSaveEventBus)
              EventBus.$emit(this.postData.postSaveEventBus);
            this.$store.dispatch(
              "modals/updateCheckModal/closeModal",
              this.postData.name
            );
          }
        })
        .catch((err) => {
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            error: err,
            text: "Midagi läks valesti.",
          });
        });
    },
    itemsInDatabaseAndNotInLocalFunc(conflict) {
      if (!conflict.databaseData) return;
      if (!conflict.startingData) return;
      let returnData = [];
      for (let item of conflict.databaseData) {
        let exists = false;
        for (let entry of conflict.startingData) {
          if (isEqual(item, entry)) exists = true;
        }
        if (!exists) returnData.push(item);
      }
      return returnData;
    },
    itemsInLocalAndNotInDatabaseFunc(conflict) {
      if (!conflict.databaseData) return;
      if (!conflict.startingData) return;
      let returnData = [];
      for (let item of conflict.startingData) {
        let exists = false;
        for (let entry of conflict.databaseData) {
          if (isEqual(item, entry)) exists = true;
        }
        if (!exists) returnData.push(item);
      }
      return returnData;
    },
  },
  mounted() {
    console.log(this.postData);
    let conflict = this.conflicts.find((x) => x.name === this.postData.name);
    this.startingData = conflict.startingData;
    this.databaseData = conflict.databaseData;
    if (typeof this.startingData !== "object") this.type = 3;
    else if (Array.isArray(this.startingData)) this.type = 2;
    else this.type = 1;
    console.log(
      typeof this.startingData,
      Array.isArray(this.startingData),
      this.type
    );
    if (this.type === 2) {
      this.itemsInDatabaseAndNotInLocal =
        this.itemsInDatabaseAndNotInLocalFunc(conflict);
      this.itemsInLocalAndNotInDatabase =
        this.itemsInLocalAndNotInDatabaseFunc(conflict);
    } else if (this.type === 1) {
      this.valuesDiffInLocalAndDataBase = {};
      for (const [key, value] of Object.entries(conflict.startingData)) {
        if (!isEqual(conflict.databaseData[key], value)) {
          this.valuesDiffInLocalAndDataBase[key] = {
            prevValues: value,
            newValues: conflict.databaseData[key],
          };
        }
      }
      console.log("ARRAY", this.valuesDiffInLocalAndDataBase);
      console.log(
        omit(conflict.startingData, function (v, k) {
          return conflict.databaseData[k] === v;
        })
      );
    }
  },
  computed: {
    ...mapGetters({
      conflicts: "modals/updateCheckModal/conflicts",
      postData: "modals/updateCheckModal/postData",
    }),
  },
};
</script>
<style scoped lang="scss"></style>
`
