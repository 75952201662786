<template>
  <div>
    <div class="modal">
      <div class="modal-body" v-on-clickaway="() => closeModal()">
        <div class="modal-header">
          <div class="modal-title">
            <h3>Vali {{ selectClient ? "klient" : "maksja" }}</h3>
          </div>
        </div>
        <div class="modal-content w-full">
          <existing-client-selector
            class="flex w-full"
            :must-be-able-to-pay="!selectClient"
            @clientUpdated="updateClient"
          />
        </div>
        <div
          v-if="selectedClient && selectedClient.warning"
          class="px-3 py-1 border border-danger"
        >
          <span class="text-danger">
            Hoiatus: {{ selectedClient.warning }}
          </span>
        </div>
        <div class="modal-bottom-bar">
          <button
            class="mr-3"
            :class="selectedClient ? 'btn-primary' : 'new-button-disabled'"
            @click="updateProjectClient"
          >
            <span class="icon typcn typcn-tick" />
            <span class="label">Salvesta</span>
          </button>
          <button class="new-button-danger" @click="closeModal">
            <span class="icon typcn typcn-times"></span>
            <span class="label">Sulge</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ExistingClientSelector from "@/components/project/ExistingClientSelector.vue";
import { mapGetters } from "vuex";

export default {
  name: "ClientSelectorModal",
  components: { ExistingClientSelector },
  props: {
    selectClient: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedClient: null,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    updateClient(e) {
      this.selectedClient = e;
    },
    updateProjectClient() {
      if (this.selectedClient) {
        this.$emit("clientSelected", this.selectedClient, this.selectClient);
      }
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      project: "pages/project/data",
    }),
  },
};
</script>

<style scoped lang="scss"></style>
