<template>
  <div>
    <task-modal
      v-if="$store.state.modals.taskModal.show"
      :data="$store.state.modals.taskModal.data"
      :user-role="
        $store.state.companyData.isClient
          ? 'R6'
          : $store.state.companyData.activeCompany.worker_permission
      "
    ></task-modal>
    <add-task-modal v-if="$store.state.modals.addTaskModal.show">
    </add-task-modal>
    <add-project-resource-modal
      v-if="$store.state.modals.addResourceModal.show"
      :data="$store.state.modals.addResourceModal.data"
    >
    </add-project-resource-modal>
    <add-project-supplier-cost-modal
      v-if="$store.state.modals.supplierCostModal.show"
      :data="$store.state.modals.supplierCostModal.data"
    ></add-project-supplier-cost-modal>
    <add-equipment-unavailability-modal
      v-if="$store.state.modals.addEquipmentUnavailabilityModal.show"
      :data="$store.state.modals.addEquipmentUnavailabilityModal.data"
    >
    </add-equipment-unavailability-modal>
    <equipment-unavailability-modal
      v-if="$store.state.modals.equipmentUnavailabilityModal.show"
      :schedule="$store.state.modals.equipmentUnavailabilityModal.data"
    ></equipment-unavailability-modal>
    <planning-modal
      v-if="$store.state.modals.taskPlanningModal.show"
      :data="$store.state.modals.taskPlanningModal.data"
    ></planning-modal>
    <file-modal
      v-if="$store.state.modals.fileModal.displayFileModal"
      :file="$store.state.modals.fileModal.fileToDisplay"
      :type="'file'"
    />
    <add-client-modal
      v-if="
        $store.state.modals.addClientModal.show &&
        !$store.state.modals.addClientModal.taskModalOpen
      "
      @close="$store.commit('modals/addClientModal/close')"
    />
    <update-check-modal v-if="$store.state.modals.updateCheckModal.show" />
  </div>
</template>

<script>
import TaskModal from "@/components/tasks/TaskModal";
import AddTaskModal from "@/components/tasks/AddTaskModal";
import AddProjectResourceModal from "@/components/project/costs/AddProjectResourceModal";
import AddProjectSupplierCostModal from "@/components/project/costs/AddProjectSupplierCostModal";
import AddEquipmentUnavailabilityModal from "@/components/company/equipment/AddEquipmentUnavailabilityModal";
import PlanningModal from "@/components/tasks/PlanningModal";
import FileModal from "./FileModal.vue";
import EquipmentUnavailabilityModal from "@/components/company/equipment/EquipmentUnavailabilityModal";
import AddClientModal from "../company/clients/AddClientModal.vue";
import UpdateCheckModal from "@/components/reusable/UpdateCheckModal.vue";
export default {
  components: {
    UpdateCheckModal,
    PlanningModal,
    AddEquipmentUnavailabilityModal,
    AddProjectResourceModal,
    TaskModal,
    AddTaskModal,
    AddProjectSupplierCostModal,
    FileModal,
    EquipmentUnavailabilityModal,
    AddClientModal,
  },
};
</script>
