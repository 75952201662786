<template>
  <div
    class="flex mobile:flex-col w-full h-full desktop:border-t desktop:border-offwhite-dark"
  >
    <div
      class="flex flex-col w-1/2 mobile:w-full border-r border-offwhite-dark mobile:border-b"
    >
      <div class="w-full bg-offwhite p-2">
        <h4>Tegelikud</h4>
      </div>

      <div class="grid grid-cols-6 bg-offwhite p-2">
        <h4>Tüüp</h4>
        <h4 class="col-span-3">Kirjeldus</h4>
        <h4>Kulu</h4>
        <h4>Kogukulu</h4>
      </div>
      <div class="flex flex-col flex-1">
        <div
          class="grid grid-cols-6 items-center"
          v-for="cost in actualCosts"
          :key="`${cost.type}${cost.id}`"
        >
          <div
            class="flex items-center gap-x-1"
            v-html="parseCostColorAndName(cost.type)"
            style="min-width: 7px"
          />
          <span class="col-span-3 py-2">{{ cost.description }}</span>
          <span>{{ cost.total_cost }}€</span>
          <span>{{ cost.total_cost_w_coefficient }}€</span>
        </div>
        <div v-if="actualCosts.length === 0" class="p-2">
          <span> Kulud puuduvad </span>
        </div>
      </div>
      <div
        v-if="actualCosts.length !== 0"
        class="grid grid-cols-6 bg-offwhite px-2 py-1"
      >
        <div class="col-span-4"><h4>Summa</h4></div>
        <span>
          {{
            round2(
              actualCosts.reduce(
                (prev, curr) => prev + parseFloat(curr.total_cost),
                0
              )
            )
          }}€
        </span>
        <span>
          {{
            round2(
              actualCosts.reduce(
                (prev, curr) =>
                  prev + parseFloat(curr.total_cost_w_coefficient),
                0
              )
            )
          }}€
        </span>
      </div>
    </div>

    <div class="flex flex-col w-1/2 mobile:w-full">
      <div class="bg-offwhite w-full p-2">
        <h4>Planeeritud</h4>
      </div>
      <div class="grid grid-cols-6 bg-offwhite p-2">
        <h4>Tüüp</h4>
        <h4 class="col-span-3">Kirjeldus</h4>
        <h4>Kulu</h4>
        <h4>Kogukulu</h4>
      </div>
      <div class="flex flex-col flex-1">
        <div
          class="grid grid-cols-6 items-center"
          v-for="cost in plannedCosts"
          :key="`${cost.type}${cost.id}`"
        >
          <div
            class="flex items-center gap-x-1"
            v-html="parseCostColorAndName(cost.type)"
            style="min-width: 7px"
          />
          <span class="col-span-3 py-2">{{ cost.description }}</span>
          <span>{{ cost.total_cost }}€</span>
          <span>{{ cost.total_cost_w_coefficient }}€</span>
        </div>
        <div v-if="plannedCosts.length === 0" class="p-2">
          <span> Planeeritud kulud puuduvad </span>
        </div>
      </div>
      <div
        v-if="plannedCosts.length !== 0"
        class="grid bg-offwhite grid-cols-6 px-2 py-1"
      >
        <div class="col-span-4">
          <h4>Summa</h4>
        </div>
        <span>
          {{
            round2(
              plannedCosts.reduce(
                (prev, curr) => prev + parseFloat(curr.total_cost),
                0
              )
            )
          }}€
        </span>
        <span>
          {{
            round2(
              plannedCosts.reduce(
                (prev, curr) =>
                  prev + parseFloat(curr.total_cost_w_coefficient),
                0
              )
            )
          }}€
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { round2 } from "@/assets/utils/commonMath";
import { mapGetters } from "vuex";
import CostTypeParser from "@/assets/mixins/CostTypeParser";

export default {
  name: "TaskCostOverview",
  props: {
    taskId: {
      type: Number,
      default: null,
    },
  },
  mixins: [CostTypeParser],
  data() {
    return {
      plannedCosts: [],
      actualCosts: [],
    };
  },
  methods: {
    round2,
    loadCombinedCosts() {
      this.apiRequest(
        `costs/${this.companyId}/task/${this.taskId}/items/`,
        "get",
        true
      ).then((res) => {
        this.plannedCosts = res.data.planned;
        this.actualCosts = res.data.costs;
      });
    },
  },
  mounted() {
    this.loadCombinedCosts();
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
  },
};
</script>

<style scoped lang="scss"></style>
