<template>
  <div class="flex flex-col" id="worker-task-selector">
    <div
      v-if="dataRetrieved && taskData.length > 0"
      class="flex flex-col overflow-y-auto h-full"
    >
      <div
        v-for="task in taskData"
        :key="task.id"
        class="p-2 cursor-pointer w-full border flex justify-between items-center rounded mobile:w-full duration-100"
        :class="
          selectedTask && task.id === selectedTask.id
            ? 'bg-primary text-white'
            : 'border-transparent'
        "
        @click="selectTask(task)"
      >
        <h4 class="truncate w-2/5">{{ task.title }}</h4>
        <span class="w-1/5">
          {{ moment(task.start_time).format("DD.MM.YYYY") }}
        </span>
        <span class="w-1/5">
          {{
            `${moment(task.start_time).format("HH:mm")} - ${moment(
              task.end_time
            ).format("HH:mm")}`
          }}
        </span>
        <span v-html="parseTaskStatus(task.status)" class="w-1/5" />
      </div>
    </div>
    <div v-if="dataRetrieved && taskData.length === 0">
      <span>Tööd puuduvad.</span>
    </div>
    <ClipLoader v-if="!dataRetrieved"></ClipLoader>
  </div>
</template>

<script>
import { RequestHandler, StatusParser } from "@/assets/mixins/GewodoMixins";
import { mapGetters } from "vuex";
import moment from "moment";
import { generateParamString } from "@/assets/utils/commonTransforms";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: "WorkerTaskSelector",
  props: {
    worker: {
      type: Object,
      default: () => {
        return {};
      },
    },
    date: {
      type: Object,
      default: null,
    },
    project: {
      type: Object,
      default: null,
    },
    selectedTask: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      taskData: [],
      dataRetrieved: false,
      moment: moment,
    };
  },
  mixins: [RequestHandler, StatusParser],
  mounted() {
    if (this.date) this.retrieveTasks();
  },
  methods: {
    retrieveTasks() {
      this.dataRetrieved = false;
      let params = {};
      if (this.date) {
        params.start_time = moment(this.date).format("YYYY-MM-DD");
        params.period = "day";
      } else {
        params.period = "all";
      }
      if (this.project) params.project = this.project.uuid;
      this.apiRequest(
        `calendar/${this.companyId}/tasks/${
          this.worker.id
        }/${generateParamString(params)}`,
        "get",
        true
      ).then((res) => {
        this.taskData = res.data;
        this.taskData = this.taskData.sort((a, b) => {
          return moment(a.start_time).unix() - moment(b.start_time).unix();
        });
        this.dataRetrieved = true;
      });
    },
    loadProjectTasks(project) {
      this.apiRequest(
        `calendar/${this.companyId}/${project.uuid}/tasks/?period=all`,
        "get",
        true
      ).then((res) => {
        this.projectTasks = res.data;
      });
    },
    selectTask(e) {
      if (this.selectedTask && this.selectedTask.id === e.id) {
        this.$emit("selectTask", null);
      } else {
        this.$emit("selectTask", e);
      }
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
  },
  watch: {
    date() {
      this.retrieveTasks();
    },
    project() {
      this.retrieveTasks();
    },
  },
  components: { ClipLoader },
};
</script>

<style lang="scss">
#worker-task-selector {
  @apply h-full;
}
</style>
