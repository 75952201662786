<template>
  <div class="flex flex-col z-50 gap-y-4">
    <div class="flex mobile:flex-col z-50 mobile:gap-y-4 gap-x-4">
      <div class="flex flex-col w-1/2 mobile:w-full mobile:pr-0 gap-y-2">
        <div class="data-container" v-if="taskData">
          <div class="data-container-header">
            <h4>Üldinfo</h4>
          </div>
          <div
            class="data-container-content data-container-content-hor gap-x-2 gap-y-2 text-sm"
          >
            <div class="flex gap-y-2 w-full gap-x-2 mobile:flex-col">
              <div class="flex gap-x-2 flex-col w-2/3 mobile:w-full">
                <div class="flex-col flex">
                  <h4>Tellimus</h4>
                  <div class="flex items-center gap-x-2 text-sm">
                    <span class="flex truncate items-center h-8">{{
                      taskData.related_object
                        ? taskData.related_object.object_name
                        : "-"
                    }}</span>
                    <button
                      v-if="isAdmin || isManager"
                      class="btn-primary"
                      @click="projectSelectorActive = true"
                    >
                      Vali
                    </button>
                    <button
                      v-if="(isAdmin || isManager) && !taskData.related_object"
                      class="btn-primary"
                      @click="
                        $store.dispatch(
                          'modals/taskModal/setShowNewProjectModal',
                          true
                        )
                      "
                    >
                      Lisa uus tellimus
                    </button>
                    <router-link
                      v-if="taskData.related_object"
                      :to="`/projects/${taskData.related_object.object_uuid}`"
                      target="_blank"
                    >
                      <button class="btn-primary">Vaata</button>
                    </router-link>
                  </div>
                </div>
                <div class="flex flex-col">
                  <div class="flex flex-col" v-if="taskData.related_object">
                    <h4>Klient</h4>
                    <div class="flex items-center gap-x-2 text-sm truncate">
                      <span class="flex truncate items-center h-8">
                        {{ taskData.related_object.client_name }}</span
                      >
                      <router-link
                        v-if="isAdmin"
                        :to="`/clients/${taskData.related_object.client_id}`"
                        target="_blank"
                      >
                        <button class="btn-primary">Vaata</button>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div
                  class="flex flex-col w-full"
                  v-if="taskData.payer && (isAdmin || isManager)"
                >
                  <div class="flex flex-col mb-2">
                    <gwd-formlabel title="Maksja" />
                    <div class="flex items-center gap-x-2 text-sm">
                      <span class="flex truncate items-center h-8">{{
                        taskData.payer.name
                      }}</span>
                      <router-link
                        v-if="isAdmin || isManager"
                        :to="`/clients/${taskData.payer.id}`"
                        target="_blank"
                      >
                        <button class="btn-primary">Vaata</button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-col gap-x-2 gap-y-1 w-1/3 mobile:w-full">
                <div class="flex gap-x-2">
                  <div class="flex flex-col flex-1">
                    <h4>Kuupäev</h4>
                    <DateSelector
                      v-model="taskTime.startDate"
                      v-if="(isAdmin || isManager) && taskTime"
                      class="text-sm"
                    />
                    <div v-else class="text-sm">
                      <span v-if="taskTime">{{
                        moment(taskTime.startDate).format("DD.MM.YYYY")
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="flex gap-x-2" v-if="taskTime && !taskData.all_day">
                  <div class="flex flex-col flex-1">
                    <div class="flex-1">
                      <h4>Algus</h4>
                    </div>
                    <div class="flex-1">
                      <TimeSelector
                        v-model="taskTime.startTime"
                        @input="handleStartTimeUpdate"
                        v-if="isAdmin || isManager"
                        class="text-sm"
                      />
                      <div v-else class="text-sm">
                        <span v-if="!taskData.all_day">{{
                          `${formatTimeUnit(
                            taskTime.startTime.hour
                          )}:${formatTimeUnit(taskTime.startTime.minute)}`
                        }}</span>
                        <span v-else class="text-sm"> Terve päev </span>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col flex-1">
                    <div class="flex-1"><h4>Kestvus</h4></div>
                    <div class="flex-1">
                      <DurationSelector
                        @updated="handleDurationUpdate"
                        :initial-duration="taskDuration"
                        v-if="isAdmin || isManager"
                        class="text-sm"
                      />
                      <div v-else class="text-sm">
                        <span v-if="!taskData.all_day">
                          {{
                            `${formatTimeUnit(
                              taskDuration.hours
                            )}:${formatTimeUnit(taskDuration.minutes)}`
                          }}
                        </span>
                        <span v-else class="text-sm"> Terve päev </span>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-1 flex-col">
                    <div class="flex-1"><h4>Lõpp</h4></div>
                    <div class="flex-1">
                      <span class="text-sm px-1" v-if="!taskData.all_day">{{
                        `${taskTime.endTime.hour}:${
                          taskTime.endTime.minute.toString().length === 1
                            ? "0" + taskTime.endTime.minute
                            : taskTime.endTime.minute
                        }`
                      }}</span>
                      <span v-else class="text-sm"> Terve päev </span>
                    </div>
                  </div>
                </div>
                <div v-else class="flex gap-x-2">
                  <div class="flex flex-col flex-1">
                    <div class="flex-1"><h4>Kestvus</h4></div>
                    <div class="flex flex-row items-center">
                      <span> Terve päev </span>
                      <gwd-checkbox
                        v-if="isAdmin"
                        v-model="taskData.all_day"
                        class="ml-auto"
                        :size="`6`"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex gap-x-2" v-if="isManager || isAdmin">
                  <div class="flex-1"><h4>Kõigile nähtav</h4></div>
                  <gwd-checkbox
                    v-if="isAdmin || isManager"
                    v-model="taskData.is_public"
                    class="ml-auto"
                    :size="`6`"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <skeleton-content v-else></skeleton-content>
        <div class="data-container desktop:hidden" v-if="canStartTimer">
          <div class="data-container-header">
            <h4>Taimer</h4>
            <div
              class="flex items-center justify-end rounded text-white font-medium gap-x-2"
            >
              <div
                class="bg-primary rounded flex font-medium items-center gap-x-2 px-2 w-32 justify-center"
                v-if="taskHasActiveTimer"
              >
                <img
                  src="/new/time.svg"
                  class="h-5 w-5 filter-to-white"
                  alt="Timer logo"
                />
                <span>{{ activeTimerDisplay }}</span>
              </div>
              <button
                class="ml-auto"
                :class="taskHasActiveTimer ? 'btn-danger' : 'btn-primary'"
                @click="taskHasActiveTimer ? stopTimer() : startTimer()"
                v-if="!timerRequestRunning"
              >
                {{ taskHasActiveTimer ? "Lõpeta" : "Alusta" }}
              </button>
              <div class="flex" v-else>
                <ClipLoader size="20px" />
              </div>
            </div>
            <span class="ml-auto font-medium"> Kogutööaeg </span>
            <span class="bg-primary px-1 text-white font-medium rounded">{{
              workerTotalWorkTime
            }}</span>
          </div>
          <div class="data-container-content" v-if="taskHasActiveTimer">
            <gwd-textarea
              type="text"
              v-model="taskWorkerInfo"
              placeholder="Lisainfo taimeri kohta"
              @input="debounceSaveTimerInfo"
              :max-length="256"
            />
          </div>
        </div>
        <div class="data-container" v-if="isAdmin || isManager">
          <div class="data-container-header">
            <h4>Kirjeldus</h4>
          </div>
          <div class="data-container-content mb-2">
            <gwd-textarea
              v-model="taskData.description"
              class="text-sm"
              :max-length="1024"
            />
          </div>
          <div class="data-container-header">
            <h4>Info töödejuhatajatele</h4>
          </div>
          <div class="data-container-content mb-2">
            <gwd-textarea
              v-model="taskData.manager_description"
              class="text-sm"
              :max-length="1024"
            />
          </div>
        </div>
      </div>
      <div
        class="flex flex-col w-1/2 mobile:w-full gap-y-2 mobile:gap-y-4 justify-start"
      >
        <div class="data-container">
          <div class="data-container-header">
            <h4>Asukoht</h4>
          </div>
          <div class="data-container-content text-sm">
            <task-location-selector></task-location-selector>
          </div>
        </div>
        <div class="data-container" v-if="isManager || isAdmin">
          <div
            class="data-container-header cursor-pointer"
            @click="editTags = !editTags"
          >
            <h4>Sildid</h4>
          </div>
          <div class="data-container-content text-sm">
            <div v-if="!editTags" class="flex items-center gap-x-1">
              <div
                v-for="selectedTag in taskData.tags"
                :key="selectedTag"
                class="px-1 bg-primary rounded duration-100 text-white"
              >
                <span> {{ selectedTag }}</span>
              </div>
            </div>
            <add-task-tag-selector v-if="editTags" :existing="true" />
          </div>
        </div>
        <div class="data-container mobile:hidden" v-if="canStartTimer">
          <div class="data-container-header">
            <h4>Taimer</h4>
            <div
              class="flex items-center justify-end rounded text-white font-medium gap-x-2"
            >
              <div
                class="bg-primary rounded flex font-medium items-center gap-x-2 px-2 w-32 justify-center"
                v-if="taskHasActiveTimer"
              >
                <img
                  src="/new/time.svg"
                  class="h-5 w-5 filter-to-white"
                  alt="Timer logo"
                />
                <span>{{ activeTimerDisplay }}</span>
              </div>
              <button
                class="ml-auto"
                :class="taskHasActiveTimer ? 'btn-danger' : 'btn-primary'"
                @click="taskHasActiveTimer ? stopTimer() : startTimer()"
                v-if="!timerRequestRunning"
              >
                {{ taskHasActiveTimer ? "Lõpeta" : "Alusta" }}
              </button>
              <div class="flex" v-else>
                <ClipLoader size="20px" />
              </div>
            </div>
            <span class="ml-auto font-medium"> Minu kogutööaeg </span>
            <span class="bg-primary px-1 text-white font-medium rounded">{{
              workerTotalWorkTime
            }}</span>
          </div>
          <div class="data-container-content" v-if="taskHasActiveTimer">
            <gwd-textarea
              type="text"
              v-model="taskWorkerInfo"
              placeholder="Lisainfo taimeri kohta"
              @input="debounceSaveTimerInfo"
              :max-length="256"
            />
          </div>
        </div>
      </div>

      <project-selector
        v-if="projectSelectorActive"
        :task="modalData.task"
        @closeModal="projectSelectorActive = false"
        class="z-50"
      />
      <location-modal
        v-if="locationModal.show"
        :start-location="locationModal.data.start_location"
        :end-location="locationModal.data.end_location"
        :task-location="taskData.location"
        @closeModal="() => (locationModal = { show: false, data: null })"
      />
    </div>
    <div
      class="flex w-full gap-x-4 flex-col mobile:gap-y-4"
      v-if="!(isAdmin || isManager)"
    >
      <div class="data-container w-full">
        <div class="data-container-header">
          <h3>Kirjeldus</h3>
        </div>
        <div class="data-container-content">
          <p>{{ taskData.description ? taskData.description : "-" }}</p>
        </div>
      </div>
      <div class="data-container">
        <div class="data-container-header">
          <h4>Töötajad</h4>
        </div>
        <div
          v-for="worker in taskData.workers"
          :key="worker.id"
          class="p-2 font-medium"
        >
          {{ worker.worker_name }}
        </div>
        <div v-if="taskData.workers.length === 0" class="p-2">
          <span>Kõik töötajad</span>
        </div>
      </div>
      <div class="data-container" v-if="taskData.equipment">
        <div class="data-container-header">
          <h3>Tehnika</h3>
        </div>
        <div class="data-container-content">
          <div
            class="flex flex-row"
            v-for="taskEquipment in taskData.equipment"
            :key="taskEquipment.id"
          >
            <h4 class="flex-1">{{ taskEquipment.name }}</h4>
            <span class="flex-1">{{
              taskEquipment.location ? taskEquipment.location.long_address : "-"
            }}</span>
          </div>
        </div>
      </div>
      <task-resource-assignments :task-id="taskData.id" class="mobile:mr-1" />
      <task-extra-cost-assignments :task-id="taskData.id" class="mr-1" />
      <task-supplier-assignments
        v-if="elevatedWorker"
        :task-id="taskData.id"
        class="mobile:mr-1"
      />
    </div>
    <div class="flex flex-col" v-if="isManager || isAdmin">
      <task-workers v-if="taskData.workers" ref="taskWorkers" />
      <task-equipment
        v-if="
          taskData.assigned_equipment_list &&
          taskData.assigned_equipment_list.length > 0 &&
          $refs.taskWorkers &&
          !$refs.taskWorkers.showEditMenu
        "
        ref="taskEquipment"
      />
      <skeleton-content
        v-if="!taskData.worker && !taskData.assigned_equipment_list"
      />
    </div>
  </div>
</template>
<script>
import ProjectSelector from "@/components/tasks/ProjectSelector";
import LocationModal from "@/components/reusable/LocationModal";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import TimeSelector from "../../reusable/TimeSelector.vue";
import DurationSelector from "@/components/reusable/DurationSelector";
import DateSelector from "../../globals/DateSelector.vue";
import TaskWorkers from "./TaskWorkers";
import { vMaska } from "maska";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import {
  formatTimeUnit,
  parseTimerData,
} from "@/assets/utils/commonTransforms";
import SkeletonContent from "../../reusable/SkeletonContent.vue";
import TaskLocationSelector from "@/components/tasks/TaskLocationSelector";
import TaskEquipment from "@/components/tasks/taskmodal/TaskEquipment.vue";
import { debounce } from "lodash";
import TaskResourceAssignments from "@/components/tasks/costs/TaskResourceAssignments.vue";
import TaskExtraCostAssignments from "@/components/tasks/costs/TaskExtraCostAssignments.vue";
import AddTaskTagSelector from "@/components/tasks/AddTaskTagSelector.vue";
import TaskSupplierAssignments from "@/components/tasks/costs/TaskSupplierAssignments.vue";
export default {
  components: {
    TaskSupplierAssignments,
    AddTaskTagSelector,
    TaskExtraCostAssignments,
    TaskResourceAssignments,
    TaskEquipment,
    ProjectSelector,
    LocationModal,
    TimeSelector,
    DateSelector,
    TaskWorkers,
    DurationSelector,
    ClipLoader,
    SkeletonContent,
    TaskLocationSelector,
  },
  directives: {
    maska: vMaska,
  },
  mounted() {
    if (this.activeTimer) this.startActiveTimerDisplay();
    this.taskTime = this.initTaskTime();
  },
  beforeDestroy() {
    clearInterval(this.activeTimerInterval);
  },
  data() {
    return {
      activeTimerDisplay: "",
      activeTimerInterval: null,
      moment: moment,
      projectSelectorActive: false,
      showTimeEntries: false,
      taskTime: null,
      taskDuration: { hours: 1, minutes: 0 },
      taskWorkerPay: 0,
      taskWorkerInfo: "",
      timerRequestRunning: false,
      locationModal: {
        show: false,
        data: null,
      },
      editTags: false,
    };
  },
  methods: {
    formatTimeUnit,
    initTaskTime() {
      let taskTime = {};
      taskTime.startDate = moment(this.taskData.start_time);
      taskTime.startTime = {
        hour: moment(this.taskData.start_time).hour(),
        minute: moment(this.taskData.start_time).minute(),
      };
      taskTime.endDate = moment(this.taskData.end_time);
      taskTime.endTime = {
        hour: moment(this.taskData.end_time).hour(),
        minute: moment(this.taskData.end_time).minute(),
      };

      let diff = moment.duration(
        moment(this.taskData.end_time).diff(moment(this.taskData.start_time))
      );
      this.taskDuration = {
        hours: diff.days() * 24 + diff.hours(),
        minutes: diff.minutes(),
      };

      return taskTime;
    },
    startTimer() {
      this.timerRequestRunning = true;
      this.$store
        .dispatch("timetracking/startTimer", this.modalData.task)
        .then(() => {
          this.timerRequestRunning = false;
          this.$store.dispatch(
            "modals/taskModal/retrieveTimerData",
            this.modalData.task
          );
          this.$store.dispatch(
            "modals/taskModal/retrieveTask",
            this.modalData.task
          );
          this.startActiveTimerDisplay();
        })
        .catch((err) => {
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            message: "Midagi läks valesti",
            error: err,
          });
          this.timerRequestRunning = false;
        });
    },
    stopTimer() {
      this.timerRequestRunning = true;
      this.$store
        .dispatch("timetracking/stopTimer", {
          estimatedWorkerPay: this.taskWorkerPay,
          taskWorkerInfo: this.taskWorkerInfo,
        })
        .then(() => {
          this.timerRequestRunning = false;
          this.$store.dispatch(
            "modals/taskModal/retrieveTimerData",
            this.modalData.task
          );
          this.$store.dispatch(
            "modals/taskModal/retrieveTask",
            this.modalData.task
          );
          clearInterval(this.activeTimerInterval);
          this.taskWorkerInfo = "";
        });
    },
    startActiveTimerDisplay() {
      this.taskWorkerInfo = this.activeTimer.info ? this.activeTimer.info : "";
      const setDisplay = () => {
        this.activeTimerDisplay = moment
          .utc(
            moment
              .duration(moment().diff(moment(this.activeTimer.stopper_start)))
              .asMilliseconds()
          )
          .format("HH:mm:ss");
      };
      this.$nextTick(() => {
        setDisplay();
        this.activeTimerInterval = setInterval(() => {
          setDisplay();
        }, 1000);
      });
    },
    handleStartTimeUpdate(e) {
      this.taskTime.startTime = e;
      let endMoment = moment(this.taskTime.startDate)
        .set({ hour: e.hour, minute: e.minute })
        .add(this.taskDuration.hours, "hour")
        .add(this.taskDuration.minutes, "minute");

      this.taskTime.endTime = {
        hour: endMoment.hour(),
        minute: endMoment.minute(),
      };
    },
    debounceSaveTimerInfo: debounce(function () {
      if (this.taskHasActiveTimer)
        this.apiRequest(
          `tasks/${this.activeTimer.task_id}/stopper/${this.activeTimer.id}/edit/`,
          "patch",
          true,
          { info: this.taskWorkerInfo }
        );
    }, 1000),
    handleEndTimeUpdate(e) {
      this.taskData.end_time = e;
      let startMoment = moment(this.taskTime.startDate)
        .hour(e.hour)
        .minute(e.minute)
        .subtract(this.taskDuration.hours, "h")
        .subtract(this.taskDuration.minutes, "m");

      this.taskTime.startTime = {
        hour: startMoment.hour(),
        minute: startMoment.minute(),
      };
    },
    handleDurationUpdate(e) {
      this.taskDuration = { hours: e.hours, minutes: e.minutes ?? 0 };
      let currState = moment(this.taskTime.startDate)
        .hour(this.taskTime.startTime.hour)
        .minute(this.taskTime.startTime.minute);
      let updatedState = moment(currState)
        .add(parseInt(e.hours), "h")
        .add(parseInt(e.minutes), "m");
      this.taskTime.endTime = {
        hour: updatedState.hour(),
        minute: updatedState.minute(),
      };
    },
  },
  computed: {
    ...mapGetters({
      modalData: "modals/taskModal/data",
      timerData: "modals/taskModal/timerData",
      workerId: "companyData/workerId",
      userId: "userData/userId",
      activeTimer: "timetracking/activeTimer",
      userRole: "companyData/userPermission",
      todos: "modals/taskModal/todos",
      taskDataClone: "modals/taskModal/taskDataClone",
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
      elevatedWorker: "companyData/elevatedWorker",
    }),
    ...mapState({
      taskData: (state) => state.modals.taskModal.taskData,
    }),
    canStartTimer() {
      return (
        (this.taskDataClone?.workers.map((x) => x.id).includes(this.workerId) &&
          this.taskData?.workers.map((x) => x.id).includes(this.workerId) &&
          (this.isDayOfTask || this.taskHasActiveTimer)) ||
        (this.taskData.is_public && this.isDayOfTask && this.taskHasActiveTimer)
      );
    },
    taskHasActiveTimer() {
      return (
        this.activeTimer && this.activeTimer.task_id === this.modalData.task
      );
    },
    isDayOfTask() {
      if (!this.taskTime?.startDate) return false;
      return moment(this.taskTime.startDate).isSame(moment(), "day");
    },
    startTime() {
      return moment(this.taskTime.startDate)
        .hour(this.taskTime.startTime.hour)
        .minute(this.taskTime.startTime.minute)
        .format("YYYY-MM-DD[T]HH:mm:ssZ");
    },
    endTime() {
      return moment(this.startTime)
        .add(this.taskDuration.hours, "hours")
        .add(this.taskDuration.minutes, "minutes")
        .format("YYYY-MM-DD[T]HH:mm:ssZ");
    },
    workerTotalWorkTime() {
      if (!this.timerData) return null;
      return parseTimerData(this.timerData, this.userId);
    },
  },
  watch: {
    "taskTime.startDate"() {
      this.taskData.start_time = this.startTime;
      this.taskData.end_time = this.endTime;
    },
    "taskTime.startTime"() {
      this.taskData.start_time = this.startTime;
      this.taskData.end_time = this.endTime;
    },
    "taskTime.endTime"() {
      this.taskData.start_time = this.startTime;
      this.taskData.end_time = this.endTime;
    },
  },
};
</script>
