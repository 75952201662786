var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-container-content data-container-table"},[_c('div',{staticClass:"gwd-table"},[_vm._m(0),_vm._l((_vm.taskData.sub_tasks),function(assignment){return _c('div',{key:assignment.worker.id,staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"worker-table items-center w-full p-2"},[_c('div',{staticClass:"flex font-medium cursor-pointer",class:_vm.timerData &&
            _vm.timerData.find(function (x) { return x.worker_id === assignment.worker.id; }) ===
              undefined
              ? 'text-danger'
              : '',on:{"click":function($event){return _vm.navigateToWorker(assignment.worker.id)}}},[_vm._v(" "+_vm._s(assignment.worker.worker_name)+" ")]),(false)?_c('div',{staticClass:"flex items-center justify-between"},[_vm._v(" "+_vm._s(((_vm.formatTimeUnit( assignment.start_time ? assignment.start_time.hour : _vm.moment(_vm.taskData.start_time).hour() )) + ":" + (_vm.formatTimeUnit( assignment.start_time ? assignment.start_time.minute : _vm.moment(_vm.taskData.start_time).minute() )) + "-" + (_vm.formatTimeUnit( assignment.end_time ? assignment.end_time.hour : _vm.moment(_vm.taskData.end_time).hour() )) + ":" + (_vm.formatTimeUnit( assignment.end_time ? assignment.end_time.minute : _vm.moment(_vm.taskData.end_time).minute() ))))+" ")]):_vm._e(),_c('div',{staticClass:"flex items-center justify-between gap-x-2"},[(assignment.start_time)?_c('time-selector',{attrs:{"pos":"t","width":"8"},model:{value:(assignment.start_time),callback:function ($$v) {_vm.$set(assignment, "start_time", $$v)},expression:"assignment.start_time"}}):_c('span',{staticClass:"w-full h-full hover:bg-offwhite hover:p-1 rounded duration-200 cursor-pointer",on:{"click":function () { return (assignment.start_time = {
                  hour: _vm.moment(_vm.taskData.start_time).hour(),
                  minute: _vm.moment(_vm.taskData.start_time).minute(),
                }); }}},[_vm._v(" "+_vm._s(("" + (_vm.moment(_vm.taskData.start_time).format("HH:mm"))))+" ")]),(assignment.start_time)?_c('button',{staticClass:"btn-danger w-5",on:{"click":function($event){assignment.start_time = null}}},[_c('span',{staticClass:"typcn typcn-times leading-none"})]):_vm._e()],1),_c('div',{staticClass:"flex items-center justify-between gap-x-2"},[(assignment.end_time)?_c('time-selector',{attrs:{"pos":"t","width":"10"},model:{value:(assignment.end_time),callback:function ($$v) {_vm.$set(assignment, "end_time", $$v)},expression:"assignment.end_time"}}):_c('span',{staticClass:"w-full h-full hover:bg-offwhite hover:p-1 rounded duration-200 cursor-pointer",on:{"click":function () { return (assignment.end_time = {
                  hour: _vm.moment(_vm.taskData.end_time).hour(),
                  minute: _vm.moment(_vm.taskData.end_time).minute(),
                }); }}},[_vm._v(" "+_vm._s(("" + (_vm.moment(_vm.taskData.end_time).format("HH:mm"))))+" ")]),(assignment.end_time)?_c('button',{staticClass:"btn-danger w-5",on:{"click":function($event){assignment.end_time = null}}},[_c('span',{staticClass:"typcn typcn-times leading-none"})]):_vm._e()],1),_c('div',{staticClass:"flex items-center gap-x-1"},[(_vm.workerHasActiveTimer(assignment.worker.profile))?_c('span',{staticClass:"h-3 w-3 bg-green rounded-full",attrs:{"title":"Töötajal on aktiivne taimer"}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.getWorkerTimeTotal(assignment.worker.profile)))])]),_c('div',{staticClass:"flex box-border items-center justify-between truncate"},[(assignment.location)?_c('span',{staticClass:"flex truncate py-1 hover:px-2 hover:bg-offwhite duration-200 rounded h-full cursor-pointer",on:{"click":function($event){return _vm.$store.dispatch("modals/taskModal/openLocationModal", {
                assignmentId: assignment.id,
                field: "location",
                currLocation: assignment.location,
              })}}},[_vm._v(" "+_vm._s(assignment.location.long_address)+" ")]):_c('span',{staticClass:"flex truncate py-1 hover:px-2 hover:bg-offwhite duration-200 rounded h-full cursor-pointer",on:{"click":function($event){return _vm.$store.dispatch("modals/taskModal/openLocationModal", {
                assignmentId: assignment.id,
                field: "location",
                currLocation: null,
              })}}},[_vm._v(" "+_vm._s(_vm.taskData.location ? _vm.taskData.location.long_address : "-")+" ")]),(assignment.location)?_c('button',{staticClass:"btn-danger w-5",on:{"click":function($event){assignment.location = null}}},[_c('span',{staticClass:"typcn typcn-times"})]):_vm._e()]),_c('div',{staticClass:"flex box-border items-center justify-between truncate"},[(assignment.end_location)?_c('span',{staticClass:"flex truncate py-1 hover:px-2 hover:bg-offwhite duration-200 rounded h-full cursor-pointer",on:{"click":function($event){return _vm.$store.dispatch("modals/taskModal/openLocationModal", {
                assignmentId: assignment.id,
                field: "end_location",
                currLocation: assignment.end_location,
              })}}},[_vm._v(" "+_vm._s(assignment.end_location.long_address)+" ")]):_c('span',{staticClass:"flex truncate py-1 hover:px-2 hover:bg-offwhite duration-200 rounded h-full cursor-pointer",on:{"click":function($event){return _vm.$store.dispatch("modals/taskModal/openLocationModal", {
                assignmentId: assignment.id,
                field: "end_location",
                currLocation: null,
              })}}},[_vm._v(" "+_vm._s(_vm.taskData.end_location ? _vm.taskData.end_location.long_address : "-")+" ")]),(assignment.end_location)?_c('button',{staticClass:"btn-danger w-5",on:{"click":function($event){assignment.end_location = null}}},[_c('span',{staticClass:"typcn typcn-times"})]):_vm._e()]),_c('div',{staticClass:"flex"},[_c('gwd-textarea',{staticClass:"w-full",attrs:{"placeholder":"Kirjeldus"},model:{value:(assignment.description),callback:function ($$v) {_vm.$set(assignment, "description", $$v)},expression:"assignment.description"}})],1),_c('div',{staticClass:"flex justify-center"},[_c('button',{staticClass:"btn-danger",on:{"click":function () { return _vm.$store.dispatch(
                  'modals/taskModal/removeAssignment',
                  assignment
                ); }}},[_c('img',{staticClass:"h-4 w-4 filter-to-white",attrs:{"src":"/bc21/trash.svg","alt":"Delete worker"}})])])]),(
          assignment.worker.equipment &&
          assignment.worker.equipment.length > 0
        )?_c('div',{staticClass:"grid grid-cols-16 p-2 gap-x-2"},[_c('div',{staticClass:"flex flex-col"},_vm._l((assignment.worker.equipment),function(equipment){return _c('div',{key:equipment.id,staticClass:"grid"},[_c('span',{staticClass:"h-2 w-4 bg-offwhite-dark rounded"}),_c('span',{staticClass:"px-2"},[_vm._v(_vm._s(equipment.name))])])}),0)]):_vm._e()])})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"worker-table gwd-table-header"},[_c('div',{staticClass:"flex"},[_c('h4',[_vm._v("Nimi")])]),_c('div',{staticClass:"flex"},[_c('h4',[_vm._v("Plan. algus")])]),_c('div',{staticClass:"flex"},[_c('h4',[_vm._v("Plan. lõpp")])]),_c('div',{staticClass:"flex"},[_c('h4',[_vm._v("Teg. tööaeg")])]),_c('div',{staticClass:"flex"},[_c('h4',[_vm._v("Lähtekoht")])]),_c('div',{staticClass:"flex"},[_c('h4',[_vm._v("Sihtkoht")])]),_c('div',{staticClass:"flex"},[_c('h4',[_vm._v("Kirjeldus")])]),_c('div',{staticClass:"flex"})])}]

export { render, staticRenderFns }