var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col gap-y-4"},[(_vm.$v.$error && _vm.$v.$invalid)?_c('div',{staticClass:"error-box"},[_c('div',{staticClass:"flex flex-col w-10/12 justify-center"},[(!_vm.$v.emailInput.email)?_c('span',{staticClass:"flex"},[_vm._v("Meiliaadress on ebakorrektne")]):_vm._e(),(
          _vm.phoneNumberInput.length > 0 &&
          (!_vm.$v.phoneNumberInput.minLength || !_vm.$v.phoneNumberInput.maxLength)
        )?_c('span',{staticClass:"flex"},[_vm._v("Telefoninumber on ebakorrektne (7-8 numbrit)")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"flex w-full flex-col gwd-form"},[_c('div',{staticClass:"gwd-form-hor-group new-contact-form-row"},[_c('h4',[_vm._v("Nimi")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.name),expression:"contact.name"}],staticClass:"add-project-input",domProps:{"value":(_vm.contact.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "name", $event.target.value)}}})]),_c('div',{staticClass:"gwd-form-hor-group new-contact-form-row"},[_c('h4',[_vm._v("Meiliaadress")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.emailInput),expression:"emailInput"}],staticClass:"add-project-input w-full",domProps:{"value":(_vm.emailInput)},on:{"input":function($event){if($event.target.composing){ return; }_vm.emailInput=$event.target.value}}})]),_c('div',{staticClass:"gwd-form-hor-group new-contact-form-row"},[_c('h4',[_vm._v("Roll")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.role),expression:"contact.role"}],staticClass:"add-project-input",domProps:{"value":(_vm.contact.role)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "role", $event.target.value)}}})]),_c('div',{staticClass:"gwd-form-group"},[_c('h4',[_vm._v("Lisainfo")]),_c('gwd-textarea',{staticClass:"add-project-input w-full",model:{value:(_vm.contact.info),callback:function ($$v) {_vm.$set(_vm.contact, "info", $$v)},expression:"contact.info"}})],1),_c('div',{staticClass:"gwd-form-group"},[_c('h4',[_vm._v("Telefoninumber")]),_c('div',{staticClass:"flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNumberInput),expression:"phoneNumberInput"}],staticClass:"add-project-input w-full",class:_vm.phoneNumberInput.length > 0 &&
            _vm.$v.$error &&
            _vm.$v.phoneNumberInput.$invalid
              ? 'shadow-dangeroutline'
              : '',attrs:{"placeholder":"Telefoninumber","type":"tel","pattern":"[0-9]*"},domProps:{"value":(_vm.phoneNumberInput)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phoneNumberInput=$event.target.value}}})])])]),_c('div',{staticClass:"flex flex-row justify-start gap-x-2"},[_c('button',{staticClass:"btn-primary",on:{"click":_vm.save}},[_c('span',{staticClass:"icon typcn typcn-tick"}),_c('span',{staticClass:"label"},[_vm._v("Salvesta")])]),_c('button',{staticClass:"new-button-danger",on:{"click":function($event){return _vm.close()}}},[_c('span',{staticClass:"icon typcn typcn-times"}),_c('span',{staticClass:"label"},[_vm._v("Tühista")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }