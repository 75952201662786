<template>
  <div class="flex w-full">
    <input v-model="duration" class="flex w-12 flex-grow px-1" />
  </div>
</template>

<script>
export default {
  props: {
    hourCap: {
      type: Number,
      default: 0,
    },
    minuteCap: {
      type: Number,
      default: 0,
    },
    initialDuration: {
      type: Object,
      default: () => {
        return { hours: 1, minutes: 0 };
      },
    },
    delimiters: {
      type: Array,
      default: () => [",", ".", ":"],
    },
  },
  data() {
    return {
      duration: "00:00",
    };
  },
  mounted() {
    this.duration = `${this.initialDuration.hours
      .toString()
      .padStart(2, "0")}:${this.initialDuration.minutes
      .toString()
      .padStart(2, "0")}`;
  },
  methods: {
    broadcastUpdate() {
      let durationObj = {
        hours: 0,
        minutes: 0,
      };
      let delimiterUsed = this.getDelimiter();
      let splitDur = this.duration.split(delimiterUsed);
      if (delimiterUsed !== ":") {
        let minute = splitDur[1] ? parseFloat(`0.${splitDur[1]}`) * 60 : 0;

        durationObj = {
          hours: splitDur[0] ? parseInt(splitDur[0]) : 0,
          minutes: minute,
        };
      } else {
        durationObj = {
          hours: parseInt(splitDur[0] ?? 0),
          minutes: parseInt(splitDur[1]),
        };
      }
      this.$emit("updated", durationObj);
    },
    getDelimiter() {
      let delimiter = this.duration
        .split("")
        .filter((x) => this.delimiters.includes(x));
      if (!delimiter.length === 0) return null;
      return delimiter[0];
    },
    checkIfHasDelimiter(input) {
      return (
        input.split("").filter((x) => this.delimiters.includes(x)).length > 0
      );
    },
    setDurationToFullDay() {
      this.duration = "08:00";
    },
  },
  watch: {
    duration: {
      handler(val, oldVal) {
        this.duration = val.replace(/[^0-9:,.]/g, "");
        const checks = [
          val.length > 5,
          val.split("").filter((x) => this.delimiters.includes(x)).length > 1,
          this.checkIfHasDelimiter(val) &&
            val.split(this.getDelimiter())[1].length > 2,
        ];

        if (checks.some((check) => check === true)) this.duration = oldVal;

        this.broadcastUpdate();
      },
      immediate: false,
    },
  },
};
</script>

<style lang="scss"></style>
