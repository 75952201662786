<template>
  <div class="flex flex-col">
    <div class="flex flex-col p-2 gap-y-2 w-auto" v-if="!selectedClient">
      <div class="flex gap-x-2 w-auto text-sm">
        <search-input v-model="searchQuery" @input="debounceSearchQuery" />
        <button
          class="btn-primary"
          @click="$store.dispatch('modals/addClientModal/open', null)"
        >
          Lisa uus
        </button>
      </div>
      <SkeletonContent v-if="!clients || !clients.results" />
      <div class="flex flex-col max-h-64 overflow-y-auto w-auto" v-else>
        <div
          v-for="client in clients.results"
          :key="client.id"
          @click="selectedClient = client"
          class="flex hover:bg-offwhite p-2 duration-100 cursor-pointer items-center gap-x-2 rounded w-full text-sm"
          :class="selectedClient === client ? 'bg-primary bg-opacity-10' : ''"
        >
          <div class="client-selector-name flex-nowrap truncate">
            <h4>{{ client.name }}</h4>
          </div>
          <div class="flex items-center gap-x-2 w-8" v-if="client.is_company">
            <div
              class="bg-primary rounded-full h-6 w-6 flex justify-center items-center"
            >
              <img
                src="/icons/briefcase.svg"
                class="h-4 filter-to-white"
                alt="Company icon"
              />
            </div>
            <span v-if="client.is_company" class="hidden">
              {{ client.register_code ? client.register_code : "-" }}</span
            >
          </div>
        </div>
        <span v-if="clients.results.length === 0"> Ettevõtteid ei leitud </span>
      </div>
      <div class="flex justify-center" v-if="clients && clients.count > 1">
        <page-changer
          :page="page"
          :count="clients.count"
          :range="2"
          @pageChange="
            (e) => {
              this.page = e;
              this.loadCompanyClients();
            }
          "
        />
      </div>
    </div>
    <div class="flex flex-col h-full p-2" v-else>
      <client-info-card
        class="flex flex-col"
        :client="selectedClient"
        @changeClient="selectedClient = null"
      />
    </div>
  </div>
</template>

<script>
import { RequestHandler } from "@/assets/mixins/GewodoMixins";
import ClientInfoCard from "@/components/reusable/ClientInfoCard";
import { generateParamString } from "@/assets/utils/commonTransforms";
import SearchInput from "@/components/reusable/SearchInput.vue";
import PageChanger from "@/components/reusable/PageChanger";
import SkeletonContent from "../../reusable/SkeletonContent.vue";
import { debounce } from "lodash";
import { mapGetters } from "vuex";
import EventBus from "@/assets/mixins/EventBus";
export default {
  props: {
    project: {
      type: Object,
      default: () => {
        return {};
      },
    },
    mustBeAbleToPay: {
      type: Boolean,
      default: false,
    },
    preSelectedClient: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      clients: null,
      selectedClient: null,
      isExisting: false,
      showAddClientModal: false,
      searchQuery: "",
      page: 1,
    };
  },
  mixins: [RequestHandler],
  mounted() {
    if (this.preSelectedClient) {
      this.selectedClient = this.preSelectedClient;
      return;
    }
    this.loadCompanyClients();
    EventBus.$on("clientAdded", this.handleAddedClient);
  },
  beforeDestroy() {
    EventBus.$off("clientAdded", this.handleAddedClient);
  },
  components: {
    ClientInfoCard,
    SearchInput,
    PageChanger,
    SkeletonContent,
  },
  methods: {
    handleClientContactUpdate(e) {
      this.$emit("clientContactsUpdated", e);
    },
    blockSaving(e) {
      this.$emit("blockSaving", e);
    },
    debounceSearchQuery: debounce(function () {
      this.loadCompanyClients();
    }, 500),
    loadCompanyClients() {
      return new Promise((resolve, reject) => {
        this.clients = null;
        let params = { page: this.page, payable: this.mustBeAbleToPay };
        if (this.searchQuery.length > 0) params.filter = this.searchQuery;

        let url = `company/${this.companyId}/clients/${generateParamString(
          params
        )}`;
        this.apiRequest(url, "get", true)
          .then((res) => {
            this.clients = res.data;
            if (this.$route.query.client) {
              this.selectedClient = res.data.results.find(
                (x) => x.id === parseInt(this.$route.query.client)
              );
              this.selectExistingClient = true;
            }
            resolve();
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
    },
    handleAddedClient(id) {
      this.apiRequest(
        `company/${this.companyId}/clients/${id}/`,
        "get",
        true
      ).then((res) => (this.selectedClient = res.data));
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
  },
  watch: {
    selectedClient() {
      this.$emit("clientDataUpdated", this.selectedClient);
    },
  },
};
</script>

<style lang="scss">
.client-selector-tab {
  @apply flex items-center bg-offwhite px-2 border-offwhite-dark py-1 cursor-pointer duration-100 font-medium;
  &:hover {
    @apply bg-offwhite-dark text-primary;
  }
}
.client-selector-tab-active {
  @apply bg-offwhite-dark text-primary;
  &:hover {
    @apply bg-offwhite-dark cursor-default;
  }
}
.client-selector-name {
  width: calc(100% - 2rem);
}
</style>
