<template>
  <div
    class="gwd-table w-full"
    v-if="taxTypes && serviceObjects && revenuesEditable"
  >
    <div class="gwd-table-header revenue-list-row" v-if="payer && showPayer">
      <span>Maksja: {{ payer.name }}</span>
      <div v-if="deleteButton" class="ml-auto">
        <button class="btn-danger" @click="removeRevenueArray">Eemalda</button>
      </div>
    </div>
    <div class="gwd-table-header revenue-list-row" v-if="!payer && showPayer">
      <div class="flex">
        <button class="btn-primary" @click="changePayer = true">
          <span class="label">Vali maksja</span>
        </button>
      </div>
      <div v-if="invoice">
        <span> Arve: {{ invoice }}</span>
      </div>
      <div v-if="deleteButton" class="ml-auto">
        <button class="btn-danger" @click="removeRevenueArray">Eemalda</button>
      </div>
    </div>
    <div class="gwd-table-header revenue-list-row">
      <span>Programmikood</span>
      <span>Kirjeldus</span>
      <div class="flex">
        <span class="w-1/2">Kogus</span>
        <span>Ühik</span>
      </div>
      <span>Hind</span>
      <span>Summa (-km)</span>
      <span>KM tüüp</span>
      <span>Summa</span>
    </div>
    <draggable v-model="revenuesEditable" v-bind="dragOptions" handle=".handle">
      <transition-group
        type="transition"
        class="flip-list"
        ref="revenueListIds"
      >
        <div
          v-for="item in revenuesEditable"
          :key="item.id"
          class="gwd-table-row revenue-list-row"
          :class="[revenuesToDelete.includes(item.id) ? 'item-to-delete' : '']"
        >
          <div class="flex pr-2">
            <select
              class="max-w-full w-full"
              v-model="item.serviceObj"
              v-if="!readOnly && !revenuesToDelete.includes(item.id)"
            >
              <option
                v-for="serviceObj in serviceObjects"
                :key="serviceObj.id"
                :value="serviceObj"
                :disabled="serviceObj.service_code.length === 2"
              >
                {{ `${serviceObj.service_code} - ${serviceObj.service_name}` }}
              </option>
            </select>
            <span
              v-else
              class="truncate"
              :title="item.serviceObj && item.serviceObj.service_name"
              >{{
                item.serviceObj && item.serviceObj.service_code
                  ? `${item.serviceObj.service_code} - ${item.serviceObj.service_name}`
                  : "-"
              }}
            </span>
          </div>
          <div class="flex pr-2">
            <input
              class="w-full max-w-full description"
              type="text"
              v-model="item.description"
              v-if="!readOnly && !revenuesToDelete.includes(item.id)"
              ref="descriptions"
            />
            <span v-else>{{ item.description }}</span>
          </div>
          <div class="pr-2 flex items-center">
            <decimal-input
              v-if="!readOnly && !revenuesToDelete.includes(item.id)"
              step="1"
              v-model.number="item.amount"
              class="w-full pr-2 amount"
              ref="amounts"
              :decimal-points="4"
            />
            <span v-else class="pr-2">
              {{ item.amount }}
            </span>
            <select
              v-model="item.unit"
              v-if="!readOnly && !revenuesToDelete.includes(item.id)"
              class="ml-auto"
            >
              <option v-for="unit in units" :value="unit" :key="unit">
                {{ unit }}
              </option>
            </select>
            <span class="flex" v-else>{{ item.unit }}</span>
          </div>
          <div class="pr-2">
            <decimal-input
              v-if="!readOnly && !revenuesToDelete.includes(item.id)"
              step="1"
              v-model.number="item.price"
              class="max-w-full w-full price"
              ref="prices"
              :decimal-points="4"
            />
            <span v-else>
              {{
                `${item.price}${currency ? currency.sign : "€"} ${
                  currencyRate && currency.code !== "EUR" && currency
                    ? `(${convertCurrency(item.price, currencyRate)}€)`
                    : ""
                }`
              }}
            </span>
          </div>
          <div class="no-hover pr-2">
            <span>
              {{
                `${round2(item.amount * item.price)}${
                  currency ? currency.sign : "€"
                }`
              }}</span
            >
          </div>
          <div class="pr-2 truncate">
            <select
              class="max-w-full w-full"
              v-model="item.taxType"
              v-if="!readOnly && !revenuesToDelete.includes(item.id)"
            >
              <option
                v-for="taxType in taxTypes.filter((x) => x.outgoing_tax_code)"
                :key="taxType.id"
                :value="taxType"
              >
                {{
                  `${taxType.description} (${
                    taxType.accounting_code ? taxType.accounting_code : "-"
                  })`
                }}
              </option>
            </select>
            <span v-else class="max-w-full truncate"
              >{{
                item.taxType
                  ? `${item.taxType.description} (${
                      item.taxType.accounting_code
                        ? item.taxType.accounting_code
                        : "-"
                    })`
                  : "-"
              }}
            </span>
          </div>
          <div>
            <span>
              {{
                `~${item.total}${currency ? currency.sign : "€"} ${
                  currencyRate && currency.code !== "EUR" && currency
                    ? "(" + convertCurrency(item.total, currencyRate) + "€)"
                    : ""
                }`
              }}
            </span>
          </div>
          <div v-if="!readOnly" class="flex flex-row items-center gap-x-2">
            <div class="flex">
              <button
                class="btn-danger"
                v-if="!revenuesToDelete.includes(item.id)"
                @click="() => revenuesToDelete.push(item.id)"
              >
                <span class="typcn typcn-trash icon text-xl leading-none" />
              </button>
              <button
                class="btn-primary"
                v-else
                @click="
                  () =>
                    revenuesToDelete.splice(
                      revenuesToDelete.findIndex((x) => x === item.id),
                      1
                    )
                "
              >
                <span
                  class="typcn typcn-arrow-back-outline leading-none text-xl"
                />
              </button>
            </div>

            <div class="flex p-1 handle bg-attention btn-primary">
              <span class="typcn typcn-th-menu icon" />
            </div>
          </div>
        </div>
      </transition-group>
    </draggable>

    <div
      v-for="(item, index) in newRevenues"
      :key="`newcost-${index}`"
      class="gwd-table-row revenue-list-row"
    >
      <div class="flex pr-2">
        <select
          v-model="item.serviceObj"
          class="w-full"
          :class="
            showInvalid && !item.serviceObj
              ? 'ring-danger ring-opacity-50 ring-2'
              : ''
          "
          @change="setDefaultServiceObjectFields(item)"
        >
          <option
            v-for="serviceObj in serviceObjects"
            :key="serviceObj.id"
            :value="serviceObj"
            :disabled="serviceObj.service_code.length === 2"
          >
            {{ `${serviceObj.service_code} - ${serviceObj.service_name}` }}
          </option>
        </select>
      </div>
      <div class="no-hover pr-2">
        <input
          type="text"
          v-model="item.description"
          class="w-full max-w-full description"
          ref="newDescriptions"
        />
      </div>
      <div class="no-hover pr-2 flex items-center">
        <decimal-input
          v-model.number="item.amount"
          :class="
            showInvalid && !item.amount
              ? 'ring-danger ring-opacity-50 ring-2'
              : ''
          "
          class="w-full pr-2 amount"
          ref="newAmounts"
          :decimal-points="4"
        />
        <select v-model="item.unit" class="ml-auto">
          <option v-for="unit in units" :value="unit" :key="unit">
            {{ unit }}
          </option>
        </select>
      </div>
      <div class="no-hover pr-2">
        <decimal-input
          step="0.01"
          v-model.number="item.price"
          class="w-full price"
          :class="
            showInvalid && !item.price
              ? 'ring-danger ring-opacity-50 ring-2'
              : ''
          "
          ref="newPrices"
          :decimal-points="4"
        />
      </div>
      <div class="no-hover pr-2">
        <span> {{ round2(item.amount * item.price) }}</span>
      </div>
      <div class="no-hover pr-2 truncate">
        <select
          v-if="taxTypes"
          v-model="item.taxType"
          class="w-full max-w-full"
          :class="
            showInvalid && !item.taxType
              ? 'ring-danger ring-opacity-50 ring-2'
              : ''
          "
        >
          <option
            v-for="taxType in taxTypes.filter((x) => x.outgoing_tax_code)"
            :key="taxType.id"
            :value="taxType"
          >
            {{
              taxType
                ? `${taxType.description} (${
                    taxType.accounting_code ? taxType.accounting_code : "-"
                  })`
                : "-"
            }}
          </option>
        </select>
      </div>
      <div>
        <span>
          {{
            `~${item.total}${currency ? currency.sign : "€"} ${
              currencyRate && currency.code !== "EUR" && currency
                ? `(${convertCurrency(item.total, currencyRate)}€`
                : ""
            }`
          }}
        </span>
      </div>
      <div class="flex flex-row items-center gap-x-2">
        <div class="flex">
          <button class="btn-danger" @click="removeNewRevenue(index)">
            <img
              src="/bc21/trash.svg"
              class="filter-to-white h-5 w-5"
              alt="Delete row"
            />
          </button>
        </div>
        <div />
      </div>
    </div>
    <div
      class="gwd-table-row revenue-list-row w-full p-2 bg-offwhite rounded-b"
    >
      <div class="flex items-center">
        <button
          class="btn-primary mr-2"
          @click="addNewRevenueLine"
          v-if="!readOnly && !loading"
        >
          Lisa uus rida
        </button>
        <saving-loader v-if="loading" />
        <button
          class="btn-primary"
          @click="displayTemplateSelector = true"
          v-if="false && !readOnly"
        >
          Lisa read mallist
        </button>
      </div>
      <div></div>
      <div></div>
      <div></div>
      <div>
        {{ `${listSum}${currency ? currency.sign : "€"} ${listSumConverted}` }}
      </div>
      {{ `${listTax}${currency ? currency.sign : "€"} ${listTaxConverted}` }}
      <div>
        {{
          `${listTotal}${currency ? currency.sign : "€"} ${listTotalConverted}`
        }}
      </div>
    </div>
    <client-selector-modal
      v-if="changePayer"
      :select-client="false"
      @closeModal="changePayer = false"
      @clientSelected="payerChanged"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import { Revenue } from "@/assets/types/Revenue";
import { convertCurrency, round2 } from "@/assets/utils/commonMath";
import ClientSelectorModal from "@/components/project/ClientSelectorModal.vue";
import DecimalInput from "../reusable/DecimalInput.vue";
import { cloneDeep, set } from "lodash";
import SavingLoader from "@/components/reusable/SavingLoader.vue";
export default {
  name: "RevenueList",
  props: {
    revenuesProp: {
      type: Array,
      default: () => [],
    },
    newRevenuesProp: {
      type: Array,
      default: () => [],
    },
    saveButton: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: Object,
      default: null,
    },
    currencyRate: {
      type: String,
      default: null,
    },
    client: {
      type: Object,
      default: null,
    },
    payer: {
      type: Object,
      default: null,
    },
    revenueId: {
      type: Number,
      default: null,
    },
    invoice: {
      type: Object,
      default: null,
    },
    showPayer: {
      type: Boolean,
      default: false,
    },
    deleteButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SavingLoader,
    ClientSelectorModal,
    draggable,
    DecimalInput,
  },
  data() {
    return {
      displayTemplateSelector: false,
      revenuesEditable: [],
      newRevenues: [],
      edit: null,
      showInvalid: false,
      revenuesToDelete: [],
      changePayer: false,
      itemStaticIndex: 0,
      loading: false,
    };
  },
  async mounted() {
    if (!this.taxTypes)
      await this.$store.dispatch("companyVariables/retrieveTaxTypes");
    if (!this.objects)
      await this.$store.dispatch("companyVariables/retrieveObjects");
    if (!this.serviceObjects)
      await this.$store.dispatch("companyVariables/retrieveServiceObjects");
    this.revenuesEditable = cloneDeep(this.revenuesProp) ?? [];
    this.newRevenues = cloneDeep(this.newRevenuesProp) ?? [];
    document.addEventListener("keydown", this.handleKeyDown);
  },
  beforeDestroy() {
    window.onfocus = () => null;
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    round2,
    convertCurrency,
    handleKeyDown(e) {
      const target = e.keyCode;
      if (![38, 40].includes(target)) return;
      e.preventDefault();
      const classList = document.activeElement.classList;
      let nodes;
      if (classList.contains("description")) {
        nodes = [
          ...(this.$refs.descriptions ?? []),
          ...(this.$refs.newDescriptions ?? []),
        ];
      } else if (classList.contains("amount")) {
        nodes = [
          ...(this.$refs.amounts ?? []),
          ...(this.$refs.newAmounts ?? []),
        ]
          .filter((x) => !!x)
          .map((x) => x.$refs.inputWindow);
      } else if (classList.contains("price")) {
        nodes = [...(this.$refs.prices ?? []), ...(this.$refs.newPrices ?? [])]
          .filter((x) => !!x)
          .map((x) => x.$refs.inputWindow);
      } else {
        return;
      }
      const match = nodes.find((x) => x === document.activeElement);
      if (match) {
        let index = nodes.indexOf(match);
        let el;
        if (target === 38) {
          if (index === 0) return;
          el = nodes[index - 1];
        } else if (target === 40) {
          if (index === nodes.length - 1) return;
          el = nodes[index + 1];
        }
        el.setSelectionRange(el.value.length, el.value.length);
        this.$nextTick(() => el.focus());
      }
    },
    setDefaultServiceObjectFields(item) {
      if (item.serviceObj) {
        set(
          item,
          "description",
          item.description ? item.description : item.serviceObj.service_name
        );
        set(
          item,
          "taxType",
          this.taxTypes.find((x) => x.id === item.serviceObj.default_vat) ??
            null
        );
        set(item, "unit", item.serviceObj.default_unit ?? null);
      }
    },
    clearInvalid() {
      this.showInvalid = true;
      this.edit = null;
      this.newRevenues = this.newRevenues.filter((x) => !x.isValid);
    },
    announceChanges() {
      this.$emit("revenueChanged", this.revenueObject);
    },
    addRevenueLine(e) {
      this.newRevenues.push(e);
    },
    removeRevenueArray() {
      this.$emit("removeRevenueArray", this.revenueId);
    },
    payerChanged(e) {
      this.$emit("togglePayer", this.revenueId, e);
      this.changePayer = false;
    },
    addNewRevenueLine() {
      this.loading = true;
      this.newRevenues.push(
        new Revenue(
          this.itemStaticIndex++,
          this.defaultServiceCode,
          0,
          null,
          this.defaultTaxType,
          0,
          null,
          ""
        )
      );
      this.loading = false;
    },
    removeNewRevenue(index) {
      this.newRevenues.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters({
      taxTypes: "companyVariables/taxTypes",
      companyId: "companyData/activeCompanyUuid",
      currencyList: "companyVariables/currencyList",
      serviceObjects: "companyVariables/serviceObjects",
      units: "companyVariables/units",
      objects: "companyVariables/objects",
    }),
    revenueObject() {
      return {
        revenues: this.revenuesEditable.map((x) => x.processedRevenue),
        newRevenues: this.newRevenues.map((x) => x.processedRevenue),
        revenuesToDelete: this.revenuesToDelete ?? [],
        listSum: this.listSum,
        listTotal: this.listTotal,
        listTax: this.listTax,
      };
    },
    activeRevenueArray() {
      return [
        ...this.revenuesEditable.filter(
          (x) => !this.revenuesToDelete.includes(x.id)
        ),
        ...this.newRevenues,
      ];
    },
    listSum() {
      if (!this.revenuesProp || !this.newRevenues) return 0;
      let arr = this.activeRevenueArray;
      if (arr.length > 0)
        return round2(
          arr.reduce(
            (prev, curr) => parseFloat(prev) + round2(curr.amount * curr.price),
            0
          )
        );
      return 0;
    },
    listTax() {
      if (!this.revenuesProp || !this.newRevenues) return 0;
      let arr = this.activeRevenueArray;
      let taxes = {};
      for (let revenue of arr) {
        const vat = revenue.taxType
          ? revenue.taxType.tax_percentage ?? "0"
          : "0";
        if (Object.keys(taxes).includes(vat.toString())) {
          taxes[vat] = taxes[vat] + round2(revenue.amount * revenue.price);
        } else {
          taxes[vat] = round2(revenue.amount * revenue.price);
        }
      }
      let taxSum = 0;
      for (const [vatPer, vatSum] of Object.entries(taxes)) {
        if (vatPer === "0") continue;
        taxSum += round2((vatSum * vatPer) / 100);
      }
      return round2(taxSum);
    },
    listTotal() {
      return round2(this.listSum + this.listTax);
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
        preventOnFilter: false,
      };
    },
    listSumConverted() {
      if (!this.currency || this.currency.code === "EUR" || !this.currencyRate)
        return "";
      return `(${this.convertCurrency(this.listSum, this.currencyRate)}€)`;
    },
    listTotalConverted() {
      if (!this.currencyRate) return "";
      return `(${this.convertCurrency(this.listTotal, this.currencyRate)}€)`;
    },
    listTaxConverted() {
      if (!this.currencyRate) return "";
      return `(${this.convertCurrency(this.listTax, this.currencyRate)}€)`;
    },
    defaultTaxType() {
      if (!this.taxTypes) return null;
      if (this.client) {
        if (this.client.default_vat_code)
          return this.taxTypes.find(
            (x) => x.id === this.client.default_vat_code
          );
        return this.taxTypes
          .filter((x) => x.outgoing_tax_code)
          .find((x) => x.default);
      } else {
        return this.taxTypes
          .filter((x) => x.outgoing_tax_code)
          .find((x) => x.default);
      }
    },
    defaultServiceCode() {
      if (!this.serviceObjects) return null;
      const defaultService =
        this.$store.state.companyData.activeCompany.default_service_code;
      if (defaultService)
        return this.serviceObjects.find((x) => x.id === defaultService);
      return null;
    },
  },
  watch: {
    revenuesProp: {
      deep: true,
      handler() {
        this.revenuesEditable = cloneDeep(this.revenuesProp) ?? [];
      },
    },
    revenuesEditable: {
      deep: true,
      handler() {
        this.announceChanges();
      },
    },
    newRevenues: {
      deep: true,
      handler() {
        this.announceChanges();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.revenue-table {
  @apply rounded border-collapse shadow p-2 bg-white w-full;
}
.revenue-list-row {
  @apply grid;
  grid-template-columns: 0.8fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.2fr;
}
.item-to-delete {
  @apply bg-danger line-through bg-opacity-10 hover:bg-danger-dark hover:bg-opacity-25 duration-100;
}

.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
