<template>
  <div class="modal">
    <div class="modal-body modal-large" v-on-clickaway="closeModal">
      <div class="modal-header p-2">
        <h3>Lisa tööaeg</h3>
      </div>
      <div class="modal-content gap-x-4">
        <div
          class="flex flex-col w-160 gap-y-4 border-r border-offwhite-dark pr-2"
          v-if="timesInited"
        >
          <div class="flex flex-col">
            <h4>Töötaja</h4>
            <div class="flex items-center">
              <span>
                {{ selectedWorker ? selectedWorker.worker_name : "-" }}</span
              >
              <button
                class="btn-primary ml-auto"
                v-if="!worker && selectedWorker"
                @click="selectedWorker = null"
              >
                Vaheta töötajat
              </button>
            </div>
          </div>
          <div v-if="!task" class="flex flex-col">
            <h4>Tellimus</h4>
            <button
              @click="projectSelectorActive = true"
              class="btn-primary"
              v-if="!selectedProject"
            >
              <span class="label">Vali</span>
            </button>
            <div v-else class="flex gap-x-2">
              <span>
                {{ selectedProject.title }}
              </span>
              <div class="flex">
                <button class="btn-danger" @click="selectedProject = null">
                  <span class="label">Eemalda</span>
                </button>
              </div>
            </div>
          </div>
          <div class="flex gap-x-2" v-if="selectedProject">
            <gwd-checkbox size="6" v-model="showAllProjectTasks" />
            <span>Näita kõiki tellimuse töid</span>
          </div>
          <div class="flex flex-col">
            <h4>Kuupäev</h4>
            <gwd-dateselector
              :value="startDate"
              @input="handleDateChange"
              background-color="offwhite"
              :shadow="true"
              :padded="true"
            />
          </div>
          <div class="flex gap-x-4">
            <div class="flex flex-col">
              <h4>Algus</h4>
              <time-selector
                :value="startTime"
                :is-moment="true"
                :full-minutes="true"
                :is-white="false"
                @input="handleStartTimeChange"
                class="flex flex-row w-full justify-star"
                :class="startTime > endTime ? 'wrong-time' : ''"
              />
            </div>
            <div class="flex flex-col">
              <h4>Lõpp</h4>
              <time-selector
                :value="endTime"
                :is-moment="true"
                :full-minutes="true"
                :is-white="false"
                @input="handleEndTimeChange"
                class="flex flex-row w-full justify-start"
                :class="startTime > endTime ? 'wrong-time' : ''"
              />
            </div>
          </div>
          <div
            class="flex flex-col border-t border-offwhite-dark gap-y-2 pt-2"
            v-if="selectedTask"
          >
            <div class="flex flex-col">
              <h4>Valitud töö</h4>
              <span class="truncate max-w-full">{{ selectedTask.title }}</span>
            </div>
            <div class="flex flex-col" v-if="selectedTask.related_object">
              <h4>Tellimus</h4>
              <span>{{ selectedTask.related_object.object_name }}</span>
            </div>
            <div class="flex">
              <div class="flex flex-col w-1/2">
                <h4>Kuupäev</h4>
                <span>{{
                  moment(selectedTask.start_time).format("DD.MM.YYYY")
                }}</span>
              </div>
              <div class="flex flex-col w-1/4">
                <h4>Algus</h4>
                <span>{{
                  moment(selectedTask.start_time).format("HH:mm")
                }}</span>
              </div>
              <div class="flex flex-col w-1/4">
                <h4>Lõpp</h4>
                <span>{{ moment(selectedTask.end_time).format("HH:mm") }}</span>
              </div>
            </div>
            <div class="flex">
              <button class="btn-danger" @click="selectedTask = null">
                Tühista
              </button>
            </div>
          </div>
        </div>
        <div class="flex flex-grow flex-col">
          <worker-task-selector
            v-if="selectedWorker && !task"
            :selected-task="selectedTask"
            :worker="selectedWorker"
            :date="showAllProjectTasks ? null : startDate"
            :project="selectedProject"
            @selectTask="handleTaskSelection"
          />
          <div class="flex flex-col w-full" v-else>
            <gwd-formlabel title="Vali töötaja" class="mb-2"></gwd-formlabel>
            <worker-card
              v-for="companyWorker in projectWorkers"
              :key="companyWorker.id"
              :data="companyWorker"
              @toggleSelect="toggleWorker(companyWorker)"
              class="flex mb-1 items-center cursor-pointer"
            >
              <span>{{ companyWorker.worker_name }}</span>
            </worker-card>
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button
          :class="selectedWorker ? 'btn-primary' : 'btn-disabled'"
          @click="selectedWorker ? (confirmPopup = true) : ''"
        >
          <span class="label">Salvesta</span>
        </button>
        <button class="btn-danger ml-3" @click="closeModal">
          <span class="label">Sulge</span>
        </button>
      </div>
      <project-selector
        v-if="projectSelectorActive"
        :convert="false"
        @closeModal="projectSelectorActive = false"
        @projectAdded="toggleProject"
        class="z-999"
      />
      <confirm-modal
        v-if="confirmPopup"
        :text="'Kas Te olete kindel, et soovite salvestada?'"
        @confirm="saveWorkerTime"
        @closeModal="confirmPopup = false"
        class="z-999"
      />
    </div>
  </div>
</template>

<script>
import { ee } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import WorkerTaskSelector from "@/components/company/workers/WorkerTaskSelector";
import ConfirmModal from "@/components/reusable/ConfirmModal";
import { StatusParser } from "@/assets/mixins/GewodoMixins";
import TimeSelector from "@/components/reusable/TimeSelector.vue";
import ProjectSelector from "@/components/tasks/ProjectSelector.vue";
import { mapGetters } from "vuex";
import WorkerCard from "@/components/tasks/WorkerCard.vue";
import EventBus from "@/assets/mixins/EventBus";

export default {
  name: "AddWorkerTime",
  components: {
    WorkerCard,
    ProjectSelector,
    ConfirmModal,
    WorkerTaskSelector,
    TimeSelector,
  },
  props: {
    worker: {
      type: Object,
      default: null,
    },
    project: {
      type: Object,
      default: null,
    },
    task: {
      type: Object,
      default: null,
    },
  },
  mixins: [StatusParser],
  data() {
    return {
      ee: ee,
      startTime: null,
      endTime: null,
      startDate: null,
      topCalendarShowing: false,
      timesInited: false,
      confirmPopup: false,
      selectedTask: null,
      selectedProject: null,
      projectSelectorActive: false,
      projectTasks: [],
      showAllProjectTasks: false,
      moment: moment,
      selectedWorker: null,
      projectWorkers: [],
    };
  },
  mounted() {
    this.initTimes();
    if (this.worker) {
      this.selectedWorker = this.worker;
    }
    if (this.project) {
      this.toggleProject(this.project);
      this.loadProjectWorkers(this.project.uuid);
    }
    if (this.task) {
      this.selectedTask = this.task;
      this.projectWorkers = this.task.workers;
    }
  },
  methods: {
    loadProjectTasks(project) {
      this.apiRequest(
        `calendar/${this.companyId}/${project.uuid}/tasks/?period=all`,
        "get",
        true
      ).then((res) => {
        this.projectTasks = res.data;
      });
    },
    toggleProject(e) {
      this.selectedProject = e;
      this.projectSelectorActive = false;
      this.loadProjectTasks(e);
    },
    handleTaskSelection(e) {
      this.selectedTask = e;
    },
    handleStartTimeChange(e) {
      this.startTime.hour(e.hour).minute(e.minute);
    },
    handleEndTimeChange(e) {
      this.endTime.hour(e.hour).minute(e.minute);
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    toggleWorker(worker) {
      this.selectedWorker = worker;
    },
    loadProjectWorkers(projectId) {
      this.apiRequest(
        `notes/${this.companyId}/${projectId}/workers/`,
        "get",
        true
      ).then((res) => {
        this.projectWorkers = res.data;
      });
    },
    initTimes() {
      this.startDate = moment();
      this.startTime = moment().minute(0).second(0);
      this.endTime = moment().minute(0).second(0).add(1, "hour");
      this.timesInited = true;
    },
    handleDateChange(e) {
      this.startDate = e;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    async saveWorkerTime() {
      let data = {
        worker: this.selectedWorker.id,
        stopper_start: this.startTimeFormatted,
        stopper_end: this.endTimeFormatted,
      };
      if (this.selectedTask) {
        data["task"] = this.selectedTask.id;
      }
      if (this.selectedProject) {
        data["project"] = this.selectedProject.uuid;
      }

      const dataCheck = await this.$store
        .dispatch("modals/updateCheckModal/compareData", {
          name: "workerPayCost",
          sendUrl: `tasks/${this.companyId}/stopper/create/`,
          sendMethods: "post",
          isAuthed: true,
          data: data,
          postSaveEventBus: "stopperSaved",
          saveCancelActions: [
            {
              type: "bus",
              action: "stopperSaved",
            },
          ],
        })
        .then((res) => {
          return res;
        });
      if (!dataCheck) return;
      this.apiRequest(
        `tasks/${this.companyId}/stopper/create/`,
        "post",
        true,
        data
      ).then((res) => {
        if (res.status === 201) EventBus.$emit("stopperSaved");
        this.$store.dispatch(
          "modals/taskModal/setShowNewWorkerTimeModal",
          false
        );
      });
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
    startTimeFormatted() {
      const time =
        moment(this.startDate).format("YYYY-MM-DD") +
        " " +
        this.startTime.format("HH:mm");
      return moment(time).format("YYYY-MM-DD[T]HH:mm:ssZ");
    },
    endTimeFormatted() {
      const time =
        moment(this.startDate).format("YYYY-MM-DD") +
        " " +
        this.endTime.format("HH:mm");
      return moment(time).format("YYYY-MM-DD[T]HH:mm:ssZ");
    },
  },
  watch: {
    selectedTask() {
      if (!this.startDate.isSame(moment(this.selectedTask.start_time), "day")) {
        this.startDate = moment(this.selectedTask.start_time);
      }
    },
  },
};
</script>
