export default {
  data() {
    return {
      userRoles: [
        {
          role: "R0",
          description: "Omanik",
        },
        {
          role: "R1",
          description: "Administraator",
        },
        {
          role: "R2",
          description: "Ettevõtte haldur",
        },
        {
          role: "R3",
          description: "Projektijuht",
        },
        {
          role: "R4",
          description: "Kõrgendatud töötaja",
        },
        {
          role: "R5",
          description: "Töötaja",
        },
      ],
      accountingRoles: [
        { key: 4, description: "Puudub", role: "R4" },
        { key: 3, description: "Sisestaja", role: "R3" },
        { key: 2, description: "Kinnitaja", role: "R2" },
        { key: 1, description: "Administraator", role: "R1" },
      ],
    };
  },
  methods: {
    getUserRole(role) {
      return this.userRoles.find((x) => x.role === role)?.description;
    },
    getAccountingRole(role) {
      return this.accountingRoles.find((x) => x.role === role)?.description;
    },
  },
};
